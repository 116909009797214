import React, { Component } from 'react';
import Loading from '../Loading/Loading';

import dotPattern from '../../../assets/images/dot-pattern.png';
import loginVideo from '../../../assets/images/login.mp4';

import './LoadingPage.css'

class LoadingPage extends Component {
    state = {  }
    render() { 
        return ( 
            <React.Fragment>

                <section className="login-container text-center d-flex align-items-center" style={{background:'RGBA(0,0,0,0.9)'}}>

                    {/* Background Video */}
                    <div style={{backgroundImage:`URL(${dotPattern})`, backgroundRepeat:'repeat', height:'100%', width:'100%', position:'absolute', zIndex:'9'}}></div>
                    <video autoPlay muted loop className="login-video">
                        <source src={loginVideo} type="video/mp4"/>
                    </video>

                    <div className="loading-page-container">
                        <Loading />
                    </div>

                </section>
                        
            </React.Fragment>
        );
    }
}
 
export default LoadingPage;