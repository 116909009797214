export default {
    timezones: [
        {
            id: 1,
            value: '1,-12',
            name:'(GMT-12:00) International Date Line West'
        },
        {
            id: 2,
            value: '2,-11',
            name:'(GMT-11:00) Midway Island, Samoa'
        },
        {
            id: 3,
            value: '3,-10',
            name:'(GMT-10:00) Hawaii'
        },
        {
            id: 4,
            value: '4,-9',
            name:'(GMT-09:00) Alaska'
        },
        {
            id: 5,
            value: '5,-8',
            name:'(GMT-08:00) Pacific Time (US and Canada); Tijuana'
        },
        {
            id: 6,
            value: '6,-7',
            name:'(GMT-07:00) Mountain Time (US and Canada)'
        },
        {
            id: 7,
            value: '7,-7',
            name:'(GMT-07:00) Chihuahua, La Paz, Mazatlan'
        },
        {
            id: 8,
            value: '8,-7',
            name:'(GMT-07:00) Arizona'
        },
        {
            id: 9,
            value: '9,-6',
            name:'(GMT-06:00) Saskatchewan'
        },
        {
            id: 10,
            value: '10,-6',
            name:'(GMT-06:00) Central America'
        },
        {
            id: 11,
            value: '11,-6',
            name:'(GMT-06:00) Central Time (US and Canada)'
        },
        {
            id: 12,
            value: '12,-6',
            name:'(GMT-06:00) Guadalajara, Mexico City, Monterrey'
        },
        {
            id: 13,
            value: '13,-5',
            name:'(GMT-05:00) Eastern Time (US and Canada)'
        },
        {
            id: 14,
            value: '14,-5',
            name:'(GMT-05:00) Bogota, Lima, Quito'
        },
        {
            id: 15,
            value: '15,-5',
            name:'(GMT-05:00) Indiana (East)'
        },
        {
            id: 16,
            value: '16,-4',
            name:'(GMT-04:00) Atlantic Time (Canada)'
        },
        {
            id: 17,
            value: '17,-4',
            name:'(GMT-04:00) Manaus'
        },
        {
            id: 18,
            value: '18,-4',
            name:'(GMT-04:00) Santiago'
        },
        {
            id: 19,
            value: '19,-4',
            name:'(GMT-04:00) Caracas, La Paz'
        },
        {
            id: 20,
            value: '20,-3.5',
            name:'(GMT-03:30) Newfoundland and Labrador'
        },
        {
            id: 21,
            value: '21,-3',
            name:'(GMT-03:00) Brasilia'
        },
        {
            id: 22,
            value: '22,-3',
            name:'(GMT-03:00) Greenland'
        },
        {
            id: 23,
            value: '23,-3',
            name:'(GMT-03:00) Buenos Aires, Georgetown'
        },
        {
            id: 24,
            value: '24,-2',
            name:'(GMT-02:00) Mid-Atlantic'
        },
        {
            id: 25,
            value: '25,-1',
            name:'(GMT-01:00) Azores'
        },
        {
            id: 26,
            value: '26,-1',
            name:'(GMT-01:00) Cape Verde Islands'
        },
        {
            id: 27,
           value: '27,0',
            name:'(GMT) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London'
        },
        {
            id: 28,
            value: '28,-0',
            name:'(GMT) Casablanca, Monrovia'
        },
        {
            id: 29,
            value: '29,1',
            name:'(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague'
        },
        {
            id: 30,
            value: '30,1',
            name:'(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb'
        },
        {
            id: 31,
            value: '31,1',
            name:'(GMT+01:00) Brussels, Copenhagen, Madrid, Paris'
        },
        {
            id: 32,
            value: '32,1',
            name:'(GMT+01:00) West Central Africa'
        },
        {
            id: 33,
            value: '33,1',
            name:'(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna'
        },
        {
            id: 34,
            value: '34,2',
            name:'(GMT+02:00) Minsk'
        },
        {
            id: 35,
            value: '35,2',
            name:'(GMT+02:00) Cairo'
        },
        {
            id: 36,
            value: '36,2',
            name:'(GMT+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius'
        },
        {
            id: 37,
            value: '37,2',
            name:'(GMT+02:00) Athens, Bucharest, Istanbul'
        },
        {
            id: 38,
            value: '38,2',
            name:'(GMT+02:00) Jerusalem'
        },
        {
            id: 39,
            value: '39,2',
            name:'(GMT+02:00) Windhoek'
        },
        {
            id: 40,
            value: '40,2',
            name:'(GMT+02:00) Harare, Pretoria'
        },
        {
            id: 41,
            value: '41,3',
            name:'(GMT+03:00) Kuwait, Riyadh'
        },
        {
            id: 42,
            value: '42,3',
            name:'(GMT+03:00) Baghdad'
        },
        {
            id: 43,
            value: '43,3',
            name:'(GMT+03:00) Nairobi'
        },
        {
            id: 44,
            value: '44,3',
            name:'(GMT+03:00) Moscow, St. Petersburg, Volgograd'
        },
        {
            id: 45,
            value: '45,3.5',
            name:'(GMT+03:30) Tehran'
        },
        {
            id: 46,
            value: '46,4',
            name:'(GMT+04:00) Abu Dhabi, Muscat'
        },
        {
            id: 47,
            value: '47,4',
            name:'(GMT+04:00) Baku'
        },
        {
            id: 48,
            value: '48,4',
            name:'(GMT+04:00) Yerevan'
        },
        {
            id: 49,
            value: '49,4',
            name:'(GMT+04:00) Tblisi'
        },
        {
            id: 50,
            value: '50,4.5',
            name:'(GMT+04:30) Kabul'
        },
        {
            id: 51,
            value: '51,5',
            name:'(GMT+05:00) Ekaterinburg'
        },
        {
            id: 52,
            value: '52,5',
            name:'(GMT+05:00) Islamabad, Karachi, Tashkent'
        },
        {
            id: 53,
            value: '53,5.5',
            name:'(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi'
        },
        {
            id: 54,
            value: '54,5.75',
            name:'(GMT+05:45) Kathmandu'
        },
        {
            id: 55,
            value: '55,6',
            name:'(GMT+06:00) Astana, Dhaka'
        },
        {
            id: 56,
            value: '56,6',
            name:'(GMT+06:00) Almaty, Novosibirsk'
        },
        {
            id: 57,
            value: '57,6',
            name:'(GMT+06:00) Sri Jayawardenepura'
        },
        {
            id: 58,
            value: '58,6.5',
            name:'(GMT+06:30) Yangon (Rangoon)'
        },
        {
            id: 59,
            value: '59,7',
            name:'(GMT+07:00) Krasnoyarsk'
        },
        {
            id: 60,
            value: '60,7',
            name:'(GMT+07:00) Bangkok, Hanoi, Jakarta'
        },
        {
            id: 61,
            value: '61,8',
            name:'(GMT+08:00) Beijing, Chongqing, Hong Kong SAR, Urumqi'
        },
        {
            id: 62,
            value: '62,8',
            name:'(GMT+08:00) Irkutsk, Ulaanbaatar'
        },
        {
            id: 63,
            value: '63,8',
            name:'(GMT+08:00) Kuala Lumpur, Singapore'
        },
        {
            id: 64,
            value: '64,8',
            name:'(GMT+08:00) Taipei'
        },
        {
            id: 65,
            value: '65,8',
            name:'(GMT+08:00) Perth'
        },
        {
            id: 66,
            value: '66,9',
            name:'(GMT+09:00) Seoul'
        },
        {
            id: 67,
            value: '67,9',
            name:'(GMT+09:00) Osaka, Sapporo, Tokyo'
        },
        {
            id: 68,
            value: '68,9',
            name:'(GMT+09:00) Yakutsk'
        },
        {
            id: 69,
            value: '69,9.5',
            name:'(GMT+09:30) Darwin'
        },
        {
            id: 70,
            value: '70,9.5',
            name:'(GMT+09:30) Adelaide'
        },
        {
            id: 71,
            value: '71,10',
            name:'(GMT+10:00) Canberra, Melbourne, Sydney'
        },
        {
            id: 72,
            value: '72,10',
            name:'(GMT+10:00) Brisbane'
        },
        {
            id: 73,
            value: '73,10',
            name:'(GMT+10:00) Hobart'
        },
        {
            id: 74,
            value: '74,10',
            name:'(GMT+10:00) Vladivostok'
        },
        {
            id: 75,
            value: '75,10',
            name:'(GMT+10:00) Guam, Port Moresby'
        },
        {
            id: 76,
            value: '76,11',
            name:'(GMT+11:00) Magadan, Solomon Islands, New Caledonia'
        },
        {
            id: 77,
            value: '77,12',
            name:'(GMT+12:00) Fiji Islands, Kamchatka, Marshall Islands'
        },
        {
            id: 78,
            value: '78,12',
            name:'(GMT+12:00) Auckland, Wellington'
        },
        {
            id: 79,
            value: '79,13',
            name:'(GMT+13:00) Nuku alofa'
        }
    ]
}