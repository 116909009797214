import React from 'react';
import './SliderCard.css';
import { FaThumbtack } from 'react-icons/fa';
import { Link } from 'react-router-dom';

// This individual sliderCard blueprint component will receive props and will return cards 
const sliderCard = (props) => {

    let image = props.imgURL !== null ? `${process.env.REACT_APP_RESOURCE_URI}/images/sliders/${props.imgURL}` : props.altImg;
    let url = props.campaign_id !== 0 ? `campaign/${props.campaign_id}` : props.url;
    let buttonText = props.buttonText !== null ? props.buttonText : 'View Offer';

    let rate;

    if (props.type === '$ per lead') {
        //Fixed, so use dollar sign
        rate = '$' + Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(props.cpa) + ' CPA';
    } else if (props.type === '% of sale') {
        //Revshare, so use percentage sign
        rate = `${Intl.NumberFormat('en-GB').format(props.cpa)}% of sale`;
    }

    return (
        <div className="mr-2">
            <div className="card" style={{
                width: "25rem",
                position: "relative"
            }}>
                <div className="card-body d-flex p-0">
                    <div className="mb-slider-img" style={{ backgroundImage: `URL(${image})` }}>
                    </div>
                    <div className="d-flex flex-column justify-content-between p-2 position-relative">
                        <p className="mb-1">{props.message}</p>
                        {
                            props.cpa && <h4 className="font-weight-bold">{rate}</h4>
                        }
                        <div>
                            {
                                props.campaign_id !== 0 ? <Link className="btn btn-primary btn-sm" to={url}>{buttonText}</Link> : <a rel="noopener noreferrer" target="_blank" className="btn btn-primary btn-sm" href={url}>{buttonText}</a>
                            }
                        </div>
                    </div>
                </div>
                {props.pinned ? <div className="mb-slider-sticky"><FaThumbtack style={{ color: "#fe3234" }} size={13} /></div> : null}

            </div>
        </div>
    );
}
export default sliderCard;

// props.imgURL, props.heading, props.cpa, props.link