import React, { Component } from 'react';
import TimezoneList from '../../data/timezonelist';

class Timezones extends Component {
    state = { 
        timezones: TimezoneList.timezones
    }
    render() { 
        return ( 
            <React.Fragment>
                { this.state.timezones.map(timezone => <option key={timezone.id} value={timezone.value}>{timezone.name}</option>) }
            </React.Fragment>
        );
    }
}
 
export default Timezones;