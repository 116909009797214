import React, { Component } from 'react';
import StatesProvinces from '../../FormElements/StatesProvinces';
import Countries from '../../FormElements/Countries';

class FormAddress extends Component {

    constructor(props) {
        super(props);
        this.formAddress = React.createRef();
        this.address_1 = React.createRef();
        this.address_2 = React.createRef();
        this.city = React.createRef();
        this.stateName = React.createRef();
        this.non_state = React.createRef();
        this.country = React.createRef();
        this.zipcode = React.createRef();
    }

    validate = () => {

        /* Check required fields */
        const input_address_1 = this.address_1.current;
        const input_address_2 = this.address_2.current;
        const input_city = this.city.current;
        const input_state = this.stateName.current;
        const input_non_state = this.non_state.current;
        const input_country = this.country.current;
        const input_zipcode = this.zipcode.current;

        /* Reset any previous custom validity settings to valid */
        input_state.setCustomValidity('');
        input_address_1.setCustomValidity('');

        if (input_address_1.checkValidity() === false || input_city.checkValidity() === false || input_country.checkValidity() === false || input_zipcode.checkValidity() === false) {
            return false
        } else if ((input_country.value === 'CA' || input_country.value === 'US') && input_state.value === '') {
            input_state.setCustomValidity('You must select a state / province.');
            return false
        } else if (input_address_1.value.length > 100) {
            input_address_1.setCustomValidity("Street address must be less than 100 characters");
        } else if (input_address_2.value.length > 100) {
            input_address_2.setCustomValidity("Address 2 must be less than 100 characters");
        } else if (input_non_state.value.length > 100) {
            input_non_state.setCustomValidity("Must be less than 100 characters");
        }
        else {
            return true
        }
    }

    saveAndContinue = async (e) => {
        e.preventDefault()
        if (this.validate()) {
            let name = this.props.values.firstname + " " + this.props.values.lastname;
            await this.props.trackApplication(this.props.appData.appToken, this.props.values.email, 'Address', this.props.values.company, name, this.props.values.address_1, this.props.values.address_2, this.props.values.city, this.props.values.state, this.props.values.country, this.props.values.zipcode, this.props.values.phone, this.props.values.mobile, this.props.values.im_handle, this.props.values.experience_description, this.props.values.experience_level)
            this.props.nextStep()
        } else {
            /* Forces html5 form validation */
            const formAddress = this.formAddress.current;
            formAddress.reportValidity();
        }
    }

    back = (e) => {
        e.preventDefault()
        this.props.prevStep();
    }

    render() {

        const { values } = this.props

        return (
            <form ref={this.formAddress}>
                <div className="form-row mb-3 text-white">
                    <div className="col text-left">
                        <label htmlFor="address_1">Street Address*</label>
                        <input
                            ref={this.address_1}
                            onChange={
                                (e) => {
                                    this.address_1.current.setCustomValidity("")
                                    this.props.handleChange('address_1')(e);
                                }
                            }
                            placeholder="Address 1"
                            defaultValue={values.address_1}
                            className="form-control"
                            required />
                    </div>
                    <div className="col text-left">
                        <label htmlFor="address_2">&nbsp;</label>
                        <input
                            ref={this.address_2}
                            onChange={(e) => {
                                this.address_2.current.setCustomValidity("")
                                this.props.handleChange('address_2')(e);
                            }}
                            placeholder="Address 2"
                            defaultValue={values.address_2}
                            className="form-control" />
                    </div>
                </div>
                <div className="form-group text-left text-white">
                    <label htmlFor="city">City*</label>
                    <input
                        ref={this.city}
                        onChange={(e) => {
                            this.city.current.setCustomValidity("")
                            this.props.handleChange('city')(e);
                        }}
                        placeholder="City"
                        defaultValue={values.city}
                        className="form-control w-50"
                        type="text"
                        required />
                </div>
                <div className="form-group mb-3 text-white text-left">
                    <label htmlFor="state">North American State/Province</label>
                    <select
                        ref={this.stateName}
                        onChange={this.props.handleChange('state')}
                        defaultValue={values.state}
                        className="form-control">
                        <option value=""></option>
                        <StatesProvinces />
                    </select>
                </div>
                <div className="form-group text-left text-white">
                    <label htmlFor="non_state">Non-North American State/Province</label>
                    <input
                        ref={this.non_state}
                        onChange={(e) => {
                            this.non_state.current.setCustomValidity("")
                            this.props.handleChange('non_state')(e);
                        }}
                        placeholder="State / Province"
                        defaultValue={values.non_state}
                        className="form-control w-50"
                        type="text" />
                </div>
                <div className="form-group mb-3 text-white text-left">
                    <label htmlFor="country">Country*</label>
                    <select
                        ref={this.country}
                        onChange={this.props.handleChange('country')}
                        defaultValue={values.country}
                        className="form-control"
                        required>
                        <option value=""></option>
                        <Countries />
                    </select>
                </div>
                <div className="form-group text-left text-white">
                    <label htmlFor="zipcode">Zip / Postal Code*</label>
                    <input
                        ref={this.zipcode}
                        onChange={(e) => {
                            this.zipcode.current.setCustomValidity("")
                            this.props.handleChange('zipcode')(e);
                        }}
                        placeholder="Zip / Postal Code"
                        defaultValue={values.zipcode}
                        className="form-control w-50"
                        type="text"
                        required />
                </div>
                <div className="d-flex flex-row-reverse">
                    <div className="pl-2 flex-1">
                        <button onClick={this.saveAndContinue} className="btn btn-lg btn-primary btn-block">Next</button>
                    </div>
                    <div className="flex-1">
                        <button onClick={this.back} className="btn btn-lg btn-secondary btn-block">Back</button>
                    </div>
                </div>
            </form>
        );
    }
}

export default FormAddress;