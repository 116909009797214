import React, { Component } from 'react';
import { Prompt } from 'react-router';
import SecurityQuestions from '../../../component/FormElements/SecurityQuestions';

class Security extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.old_password = React.createRef();
        this.password2 = React.createRef();
        this.password3 = React.createRef();
    }

    state = {
        editing: false,
        security: {
            question1: {
                question_id: this.props.security.question1.question_id,
                description: this.props.security.question1.description,
                answer: this.props.security.question1.answer
            },
            question2: {
                question_id: this.props.security.question2.question_id,
                description: this.props.security.question2.description,
                answer: this.props.security.question2.answer
            }
        },
        oldPassword: '',
        newPassword1: '',
        newPassword2: '',
        isPasswordMatching: false,
        newPasswordRequired: false,
        isFormHalfFilled: false,
        newQuestion1: 0,    // Storing the temp question IDs in newQuestion1 and newQuestion2
        newQuestion2: 0
    }

    validate = (e) => {
        //The actual password will be validated in the backend. We only validate whether the new passwords match here.
        // e.preventDefault();

        this.newPasswordInputHandler();
        // Calling this function to enable required on Confirm Password field, which otherwise don't need to be required

        e.persist();
        const input_password2 = this.password2.current.value;
        const input_password3 = this.password3.current.value;

        if (input_password2.length > 0 && input_password3.length > 0) {
            if (input_password2 !== input_password3) {
                this.setState({ ...this.state, isPasswordMatching: false }, () => {
                    e.target.setCustomValidity('Your new passwords do not match.');
                    e.target.reportValidity();
                    return false;
                });
            }
            else {
                this.setState({ ...this.state, isPasswordMatching: true }, () => {
                    this.password3.current.setCustomValidity('');
                    this.password2.current.setCustomValidity('');
                });
                return true;
            }
        } else {
            return true;
        }
    }

    setEditing = (e) => {
        e.preventDefault();
        this.setState({
            ...this.state,
            editing: !this.state.editing,
            security: this.state.security,
            newQuestion1: 0,    // Resetting the temp values if user doesn't update.
            newQuestion2: 0,
            isFormHalfFilled: false
        });
    }

    saveSettings = (e) => {
        e.preventDefault();

        if (!this.state.newPassword1 || this.state.isPasswordMatching) {
            /* Do form submission */

            this.setState({
                editing: !this.state.editing,
                security: {
                    question1: {
                        question_id: e.target.security_q1.value,
                        description: e.target.security_q1.options[e.target.security_q1.selectedIndex].text,
                        answer: e.target.security_a1.value
                    },
                    question2: {
                        question_id: e.target.security_q2.value,
                        description: e.target.security_q2.options[e.target.security_q2.selectedIndex].text,
                        answer: e.target.security_a2.value
                    }
                },
                oldPassword: e.target.password.value,
                newPassword1: e.target.password2.value,
                newPassword2: e.target.password3.value,
                newPasswordRequired: false,
                isFormHalfFilled: false
            }, () => {

                const authtoken = localStorage.getItem('mb-auth-token');

                fetch(`${process.env.REACT_APP_API_URL}/affiliate/securityDetails`, {
                    method: "POST",
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-app-token': authtoken
                    },
                    body: JSON.stringify({
                        password: this.state.oldPassword,
                        newPassword1: this.state.newPassword1,
                        newPassword2: this.state.newPassword2,
                        security_q1: this.state.security.question1.question_id,
                        security_a1: this.state.security.question1.answer,
                        security_q2: this.state.security.question2.question_id,
                        security_a2: this.state.security.question2.answer
                    })
                })
                    .then(res => res.json())
                    .then(result => {
                        if (result["success"] === true) {
                            this.props.showSystemMessage("success", result.message);
                        } else if (result["success"] === false) {
                            this.setState({ ...this.state, editing: true }, () => {
                                this.props.showSystemMessage("error", result.errors.message);
                            });
                        } else {
                            this.props.showSystemMessage("error", result.errors.message);
                        }
                    })
                    .catch(error => this.props.showSystemMessage("error", error.message));
            });
        } else {
            /* Forces html5 form validation */
            const form = this.form.current;
            const input_old_password = this.old_password.current;
            const input_password3 = this.password3.current;

            form.reportValidity();

            /* Reset any previous custom validity settings to valid. If you don't do this the form will remain invalid. This is different than
            the registration form because we're doing this on form submit rather than on button click. */
            input_old_password.setCustomValidity('');
            input_password3.setCustomValidity('');
        }
    }

    // IMPORTANT: Storing the new selected question in temporary states(newQuestion1 & newQuestion2) as we don't want to change the original states before user actually submits the form.

    questionChangeHandler = (event) => {
        if (event.target.name === 'security_q1') {
            this.setState({
                isFormHalfFilled: true,
                newQuestion1: parseInt(event.target.value),
                newQuestion2: this.state.newQuestion2 !== 0 ? this.state.newQuestion2 : this.state.security.question2.question_id
            });
        } else if (event.target.name === 'security_q2') {
            this.setState({
                isFormHalfFilled: true,
                newQuestion2: parseInt(event.target.value),
                newQuestion1: this.state.newQuestion1 !== 0 ? this.state.newQuestion1 : this.state.security.question1.question_id
            });
        }
    }

    //Re-enter password field is mandatory only if a new password field has input.
    newPasswordInputHandler = () => {
        if (this.password2.current.value.length > 0) {
            this.setState({ ...this.state, newPasswordRequired: true, isFormHalfFilled: true });
        } else {
            this.setState({ ...this.state, newPasswordRequired: false });
        }
    }

    inputChangeHandler = () => {
        this.setState({ isFormHalfFilled: true });
    }

    render() {

        let confirmNewPasswordInput =

            this.state.newPasswordRequired ?
                <input
                    ref={this.password3}
                    name="password3"
                    className="form-control"
                    type="password"
                    placeholder="Re-enter New Password"
                    onChange={(e) => this.validate(e)}
                    required>
                </input>
            :
                <input
                    ref={this.password3}
                    name="password3"
                    className="form-control"
                    type="password"
                    placeholder="Re-enter New Password"
                    onChange={(e) => this.validate(e)}>
                </input>;         

        return (
            <div className="card my-2 max-width-750">                
                <Prompt
                    when={this.state.isFormHalfFilled}
                    message="Are you sure you want to leave? Changes you made may not be saved."
                />
                <form
                    ref={this.form}
                    onSubmit={this.saveSettings}>
                    <div className="card-header d-flex align-items-center">
                        <div className="flex-1">Security</div>
                        {this.props.showEdit &&
                            <div className="text-right">
                                {this.state.editing ?
                                    <React.Fragment>
                                        <button className="btn btn-sm btn-outline-danger mr-2" type="button" onClick={this.setEditing}>Cancel</button>
                                        <button className="btn btn-success btn-sm" type="submit">Save</button>
                                    </React.Fragment>
                                    :
                                    <button className="btn btn-primary btn-sm" onClick={this.setEditing}>Edit</button>
                                }
                            </div>
                        }
                    </div>
                    <div className="card-body p-0">
                        <table className="table table-card">
                            <tbody>
                                <tr>
                                    <td className="no-border">Password</td>
                                    <td style={{ width: '70%' }} className="no-border">
                                        {this.state.editing === false ?
                                            <div>************</div>
                                            :
                                            <React.Fragment>
                                                <label for="password">Current Password</label>
                                                <input
                                                    ref={this.old_password}
                                                    name="password"
                                                    className="form-control mb-2"
                                                    type="password"
                                                    placeholder="Current Password"
                                                    onChange={this.inputChangeHandler}
                                                    required>
                                                </input>
                                                <label for="password">New Password</label>
                                                <input
                                                    ref={this.password2}
                                                    name="password2"
                                                    className="form-control mb-2"
                                                    type="password"
                                                    placeholder="New Password"
                                                    onChange={(e) => this.validate(e)}
                                                ></input>
                                                <label for="password">Re-enter New Password</label>
                                                {confirmNewPasswordInput}
                                            </React.Fragment>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Security Question 1</td>
                                    <td>
                                        {this.state.editing === false ?
                                            <div>{this.state.security.question1.description}</div>
                                            :
                                            <React.Fragment>
                                                <select
                                                    name="security_q1"
                                                    defaultValue={this.state.security.question1.question_id}
                                                    className="form-control mb-2"
                                                    required
                                                    onChange={this.questionChangeHandler}>
                                                    <option value=""></option>
                                                    <SecurityQuestions selectedValue={this.state.security.question1.question_id} questionGroup={1} />
                                                </select>
                                                <input
                                                    name="security_a1"
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue={this.state.security.question1.answer}
                                                    onChange={this.inputChangeHandler}
                                                    required>
                                                </input>
                                            </React.Fragment>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Security Question 2</td>
                                    <td>
                                        {this.state.editing === false ?
                                            <div>{this.state.security.question2.description}</div>
                                            :
                                            <React.Fragment>
                                                <select
                                                    name="security_q2"
                                                    defaultValue={this.state.security.question2.question_id}
                                                    className="form-control mb-2"
                                                    required
                                                    onChange={this.questionChangeHandler}>
                                                    <option value=""></option>
                                                    <SecurityQuestions selectedValue={this.state.security.question2.question_id} questionGroup={2} />
                                                </select>
                                                <input
                                                    name="security_a2"
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue={this.state.security.question2.answer}
                                                    onChange={this.inputChangeHandler}
                                                    required>
                                                </input>
                                            </React.Fragment>
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </form>
            </div>
        );
    }
}

export default Security;
