import React, { Component } from 'react';
// import {Transition} from 'react-transition-group/Transition';
import { CSSTransition } from 'react-transition-group';
import Waypoint from 'react-waypoint';
import SingleCampaign from './SingleCampaign/SingleCampaign';

import './CampaignCard.css';

class CampaignCard extends Component {

    _isMounted = false;

    state = {
        in: false,
        highlightCssClass: "Campaigncard mb-card transparent highlighted"
    }

    handleWaypointEnter = () => {
        /* Create a delay before the campaigncard fades in */
        setTimeout(() => {
            if (this._isMounted) {  /* Change state only if the component is mounted */
                this.setState({
                    in: true,
                    highlightCssClass: "Campaigncard mb-card highlighted"
                })
            }
        }, 500);
    }

    componentDidMount() {
        this._isMounted = true;
    }


    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {

        let classes;

        if (this.props.highlighted === 'Y') {
            /* If this is a highlighted campaign */
            classes = this.state.highlightCssClass;
            return (
                <React.Fragment>
                    <Waypoint
                        onEnter={this.handleWaypointEnter}
                        bottomOffset='20%'>
                    </Waypoint>
                    <CSSTransition
                        in={this.state.in}
                        appear={true}
                        classNames="fade"
                        timeout={500}>
                        <SingleCampaign
                            classes={classes}
                            campaignId={this.props.campaignId}
                            name={this.props.name}
                            rate={this.props.rate}
                            rateType={this.props.rateType}
                            thumbnail={this.props.thumbnail}
                            highlighted={this.props.highlighted} />
                    </CSSTransition>
                </React.Fragment>
            );
        } else {
            /* If this is NOT a highlighted campaign */
            classes = "Campaigncard mb-card";
            return <SingleCampaign
                classes={classes}
                campaignId={this.props.campaignId}
                name={this.props.name}
                rate={this.props.rate}
                rateType={this.props.rateType}
                thumbnail={this.props.thumbnail}
                highlighted={this.props.highlighted} />
        }
    }
}

export default CampaignCard;