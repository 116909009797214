import React from 'react';

const suppressionListModal = (props) => {

    const suppressionList = props.list;

    return (
        <div>
            <div className="modal-header">
                <h6 className="modal-title">
                    Suppression List Links
                </h6>
            </div>
            <div className="modal-body">
                {suppressionList.length > 0 ? suppressionList.map(listItem => <div className="p-2" key={listItem.url}><a href={listItem.url} target="_blank" rel="noopener noreferrer">{listItem.url}</a></div>) : <div className="alert-danger p-2">No Links found</div>}
            </div>
        </div>
    );
}


export default suppressionListModal;