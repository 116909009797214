import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { AlertTriangle } from 'react-feather';

class MissingMobileModal extends Component {
    render() {
        return (
            <div>
                <div className="modal-header">
                    <h6 className="modal-title">Missing Mobile Phone Number</h6>
                    <button type="button" className="close" onClick={this.props.modalClosed}>
                        <span aria-hidden="true">x</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div>
                        <div className="text-center mb-3"><AlertTriangle color="red" size={25} /></div>
                        <p style={{ fontSize: "14px" }}>Please provide your mobile phone number so you can receive SMS codes.</p>
                    </div>
                    <div className="text-center">
                        <Link to='/settings' className="btn btn-sm btn-info">Go to Settings</Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default MissingMobileModal;