import React from 'react';

const autoCompleteList = (props) => (<li
    id="quicksearch-autocomplete"
    className="ui-menu-items ui-state-focus"
    onMouseDown={props.listClicked}
    tabIndex="-1"
    offerid={props.offerId} name={props.name}>
    {props.name}
</li>);

export default autoCompleteList;