import React, { Component } from 'react';
import SidebarItem from '../SidebarItem/SidebarItem'
import Loading from '../../UI/Loading/Loading';

class RightSideBarSection extends Component {

    state = {
        dataLoaded: false,
        campaigns: []
    }

    componentWillMount() {
        // Get the affiliate's API token
        const mbToken = localStorage.getItem('mb-auth-token');

        /* Get the affiliate object from the DB and populate the state information */
        fetch(`${process.env.REACT_APP_API_URL}/campaigns/${this.props.for}?page=0&limit=5`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': mbToken
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result["success"] === true) {
                        this.setState({
                            dataLoaded: true,
                            campaigns: result.campaigns
                        })
                    } else if (result["success"] === false) {
                        this.props.showSystemMessage("error", "There was an error fetching the data. Please try again.");
                        this.setState({
                            dataLoaded: true
                        })
                    } else {
                        this.props.showSystemMessage("error", result.errors.message);
                        this.setState({
                            dataLoaded: true
                        })
                    }
                },
                (error) => {
                    this.props.showSystemMessage("error", "Connection error.");
                    this.setState({
                        dataLoaded: true
                    })
                }
            )
    }

    render() {

        // Not showing anything if there's not a single campaign for the category
        if (this.state.campaigns.length === 0 && this.state.dataLoaded) {
            return (
                <div></div>
            );
        }

        let sidebarSectionTitle;

        // Keeping bookmarked here for the sake of showing content

        if (this.props.for === 'recentlyapproved') {
            sidebarSectionTitle = 'Recently Approved';
        } else if (this.props.for === 'ampicks') {
            sidebarSectionTitle = `${this.props.affiliate.affiliate_manager.name}'s picks`;
        } else if (this.props.for === 'populargamingoffers') {
            sidebarSectionTitle = 'Popular Gaming Offers';
        } else if (this.props.for === 'bookmarked') {
            sidebarSectionTitle = "Bookmarked";
        } else if (this.props.for === 'suggested') {
            sidebarSectionTitle = "Suggested For You";
        }

        return (

            <React.Fragment>

                <div style={{ color: "#6c757d", fontSize: "0.9rem", textAlign: "start" }}>{sidebarSectionTitle}</div>
                <hr style={{ margin: "0.25rem" }} />

                {this.state.dataLoaded && this.state.campaigns.length > 0 ?
                    <React.Fragment>

                        {this.state.campaigns.map(campaign => (
                            <SidebarItem
                                key={campaign.campaign_id}
                                offerId={campaign.campaign_id}
                                name={campaign.name}
                                rate={campaign.rate_text}
                                thumbnail={campaign.thumbnail}
                                rateType={campaign.rate_type}
                            />
                        ))}

                    </React.Fragment>
                    :
                    <Loading />
                }

            </React.Fragment>
        );
    }
}

export default RightSideBarSection;