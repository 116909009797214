import React from 'react';

const resourceCard = (props) => {
    return (
        <div className="card m-1 resource-card">
            <img className="card-img-top" src={`${process.env.REACT_APP_RESOURCE_URI}/images/resources/${props.resource.image_name}`} alt={props.resource.name} />
            <div className="card-body d-flex flex-column justify-content-between">
                <h5 className="card-title">{props.resource.name}</h5>
                <p className="card-text flex-1">{props.resource.description}</p>
                {props.resource.promo_description &&
                    <div className="alert alert-success">
                        {props.resource.promo_code &&
                            <div className="font-weight-bold">{props.resource.promo_code}</div>
                        }
                        {props.resource.promo_description}
                    </div>
                }
                <a target="_blank" href={props.resource.URL} className="btn btn-primary" rel="noopener noreferrer">Learn More</a>
            </div>
        </div>
    );
}
export default resourceCard;


// ../../../ assets / images / ${ props.image_name }.jpg