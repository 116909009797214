import React, { Component } from 'react';
import { Prompt } from 'react-router';

import EmailPreferencesWarning from './EmailPreferencesWarning';
import { Modal } from 'reactstrap';

import { yesNo } from '../../../assets/JS/functions.js';

class EmailPreferences extends Component {

    state = {
        editing: false,
        isFormHalfFilled: false,
        email: this.props.email,
        affiliateFlags: this.props.affiliateFlags,
        emailPreferences: {
            general_mailings: {
                value: this.props.preferences.general_mailings,
                text: yesNo(this.props.preferences.general_mailings)
            },
            campaign_notices: {
                value: this.props.preferences.campaign_notices,
                text: yesNo(this.props.preferences.campaign_notices)
            }
        },
        showWarningModal: false
    }

    setEditing = (e) => {
        e.preventDefault();
        this.setState({
            editing: !this.state.editing,
            emailPreferences: this.state.emailPreferences,
            isFormHalfFilled: false
        });
    }

    emailPrefWarningHandler = () => {
        this.setState({ showWarningModal: !this.state.showWarningModal });
    }

    inputChangeHandler = (e) => {
        e.preventDefault();
        e.target.setCustomValidity('');
        e.target.reportValidity();
        this.setState({ isFormHalfFilled: true });
    }

    validateEmail = async (email) => {

        const authToken = localStorage.getItem('mb-auth-token');

        return await fetch(`${process.env.REACT_APP_API_URL}/affiliate/validateEmail?email=${email}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authToken
            }
        }).then(res => res.json())
            .then(result => {
                if (result["success"] === true) {
                    return result.isValid;
                } else {
                    return false;
                }
            })
            .catch(error => {
                console.log('error: ', error);
            });
    }

    saveSettings = async (e) => {
        e.preventDefault();
        e.persist();

        let incorrectEmailFlag = this.state.affiliateFlags.includes(121);

        // Adding email validation

        if (e.target.email.value) {

            // If invalidEmailFlag is true, new email ID cannot be same as the current one

            if (incorrectEmailFlag && (this.state.email === e.target.email.value)) {
                e.target.email.setCustomValidity('We are unable to send messages to this email address. Please update it to a valid email address.');
                e.target.email.reportValidity();
                return;
            }
            try {
                const validateEmail = await this.validateEmail(e.target.email.value);

                if (!validateEmail) {
                    e.target.email.setCustomValidity('This email address is invalid');
                    e.target.email.reportValidity();
                    return;
                }
            } catch (error) {
                console.log('Error while validating email address: ', error);
            }
        }

        /* Do form submission */
        this.setState({
            // editing: !this.state.editing,
            isFormHalfFilled: false,
            email: e.target.email.value,
            emailPreferences: {
                general_mailings: {
                    value: e.target.general_mailings.value,
                    text: e.target.general_mailings.options[e.target.general_mailings.selectedIndex].text
                },
                campaign_notices: {
                    value: e.target.campaign_notices.value,
                    text: e.target.campaign_notices.options[e.target.campaign_notices.selectedIndex].text
                }
            }
        }, () => {
            const authtoken = localStorage.getItem('mb-auth-token');

            fetch(`${process.env.REACT_APP_API_URL}/affiliate/emailPreferences`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-app-token': authtoken
                },
                body: JSON.stringify({
                    email: this.state.email,
                    general_mailings: this.state.emailPreferences.general_mailings.value,
                    campaign_notices: this.state.emailPreferences.campaign_notices.value
                })
            })
                .then(res => res.json())
                .then(result => {
                    if (result["success"] === true) {
                        this.setState({ editing: false }, () => {
                            this.props.updateEmailFlag();
                            return this.props.showSystemMessage("success", result.message);
                        });
                    } else if (result["success"] === false) {
                        this.setState({
                            email: this.props.email,
                            emailPreferences: {
                                general_mailings: {
                                    value: this.props.preferences.general_mailings,
                                    text: yesNo(this.props.preferences.general_mailings)
                                },
                                campaign_notices: {
                                    value: this.props.preferences.campaign_notices,
                                    text: yesNo(this.props.preferences.campaign_notices)
                                }
                            },
                        }, () => { this.props.showSystemMessage("error", result.errors.message) })
                    }
                })
                .catch(error =>
                    this.props.showSystemMessage("error", error.message)
                );
        });
    }

    render() {
        return (
            <React.Fragment>

                <Modal isOpen={this.state.showWarningModal} toggle={this.emailPrefWarningHandler} className="modal-lg">
                    <EmailPreferencesWarning modalClosed={this.emailPrefWarningHandler} />
                </Modal>

                <Prompt
                    when={this.state.isFormHalfFilled}
                    message="Are you sure you want to leave? Changes you made may not be saved?" />

                <div className="card my-2 max-width-750">
                    <form onSubmit={this.saveSettings}>
                        <div className="card-header d-flex align-items-center">
                            <div className="flex-1">Email Preferences</div>
                            {this.props.showEdit &&
                                <div className="text-right">
                                    {this.state.editing ?
                                        <React.Fragment>
                                            <button className="btn btn-sm btn-outline-danger mr-2" type="button" onClick={this.setEditing}>Cancel</button>
                                            <button className="btn btn-success btn-sm" type="submit">Save</button>
                                        </React.Fragment>
                                        :
                                        <button className="btn btn-primary btn-sm" onClick={this.setEditing}>Edit</button>
                                    }
                                </div>
                            }
                        </div>
                        <div className="card-body p-0">
                            <table className="table table-card">
                                <tbody>
                                    <tr>
                                        <td>Email</td>
                                        <td>
                                            {this.state.editing === false ?
                                                <div>{this.state.email}</div>
                                                :
                                                <input
                                                    name="email"
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue={this.state.email}
                                                    onChange={this.inputChangeHandler}
                                                    required>
                                                </input>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>General Mailings</td>
                                        <td style={{ width: '70%' }}>
                                            {this.state.editing === false ?
                                                <div>{this.state.emailPreferences.general_mailings.text}</div>
                                                :
                                                <select
                                                    name="general_mailings"
                                                    defaultValue={this.state.emailPreferences.general_mailings.value}
                                                    className="form-control"
                                                    onChange={this.inputChangeHandler}
                                                    required>
                                                    <option value="N">No</option>
                                                    <option value="Y">Yes</option>
                                                </select>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Campaign Notices</td>
                                        <td>
                                            {this.state.editing === false ?
                                                <div>{this.state.emailPreferences.campaign_notices.text}</div>
                                                :
                                                <div>
                                                    <select
                                                        name="campaign_notices"
                                                        defaultValue={this.state.emailPreferences.campaign_notices.value}
                                                        className="form-control"
                                                        onChange={this.inputChangeHandler}
                                                        required>
                                                        <option value="N">No</option>
                                                        <option value="Y">Yes</option>
                                                    </select>

                                                    <button type="button" className="btn btn-sm btn-link p-0 m-0" onClick={this.emailPrefWarningHandler}> Read warnings before changing </button>
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        );
    }
}

export default EmailPreferences;