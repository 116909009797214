import React from 'react';
import { NavLink } from 'react-router-dom';

// import './NavigationItem.css';

const navigationItem = (props) => (
    // <a className={"dropdown-item "+ props.styleName} href={props.link} >{props.children}</a>
    // <a className={props.styleName} href={props.link} >{props.children}</a>

    <NavLink to={props.link} className={props.styleName} activeClassName="active-nav">{props.children}</NavLink>
);

export default navigationItem;