import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import './TrafficTypeItem.css';

import RadioButton from '../RadioButton/RadioButton';

// Need a way to pass all the options as an array or list. Hoping to get as an array where individual item as a property indicating allowed or not
// Better to be a key:value pair

class trafficTypeItem extends Component {

    render() {

        let text;
        let tooltip;
        let classes;
        let value;

        if (this.props.allowed === "Y") {
            classes = "mb-traffic allowed";
        } else {
            classes = "mb-traffic not-allowed";
        }

        switch (this.props.trafficType) {
            case "contextual_traffic":
                text = "Contextual";
                tooltip = "Pop overs/unders, in text ads";
                value = "C";
                break;
            case "display_traffic":
                text = "Display";
                tooltip = "Banner Images";
                value = "S";
                break;
            case "search_traffic":
                text = "Search";
                tooltip = "Google, Yahoo, Bing";
                value = "R";
                break;
            case "social_media_traffic":
                text = "Social";
                tooltip = "Facebook, Instagram, LinkedIn";
                value = "L";
                break;
            case "native_traffic":
                text = "Native";
                tooltip = "Ads integrated into content";
                value = "N";
                break;
            case "mobile_traffic":
                text = "Mobile Ads";
                tooltip = "In-app push Notifications";
                value = "M";
                break;
            case "email_traffic":
                text = "Email";
                tooltip = "Solo email, newsletters";
                value = "E";
                break;
            case "incentive_traffic":
                text = "Incentive"
                tooltip = "Points, etc.";
                value = "I";
                break;
            case "brand_bid_traffic":
                text = "Brand Bidding"
                tooltip = "Brand Bidding";
                value = "B";
                break;
            default:
                throw new Error("Invalid trafficType");
        }

        if (this.props.type === 'description') {
            return (
                <React.Fragment>
                    <span className={classes} data-tip={tooltip}>{text}</span>
                    <ReactTooltip />
                </React.Fragment>
            );
        } else if (this.props.type === 'radio') {
            if (this.props.allowed === 'Y') {
                return (
                    <RadioButton buttonName={text} id={text} tooltip={tooltip} value={value} type="traffic" changed={this.props.action} />
                )
            } else {
                // Must return something, so we return false.
                return false;
            }

        } else if (this.props.type === 'option') {
            if (this.props.allowed === 'Y') {
                return (
                    <option value={value}>{text}</option>
                )
            } else {
                // Must return something, so we return false.
                return false;
            }
        }


    }
}

export default trafficTypeItem;