import React, { Component } from 'react';
import { Modal } from 'reactstrap';

import OTPSetup from '../../Login/OTP/OTPsetup';
import OTPSuccess from '../../Login/OTP/OTPsuccess';

class TwoFactorAuthentication extends Component {

    state = {
        showOTPcancelButton: false,
        otp_key: this.props.otpDetails.otp_key,
        show2FaSetup: false,
        show2FaSuccess: false
    }

    toggleOTPcancelButton = () => {
        this.setState({ showOTPcancelButton: true });
    }

    handle2FAcancel = () => {
        const authtoken = localStorage.getItem('mb-auth-token');

        fetch(`${process.env.REACT_APP_API_URL}/authenticationApp/otpCancel`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authtoken
            }
        })
            .then(res => res.json())
            .then(result => {
                if (result["success"] === true) {
                    if (result["otpDeactivated"] === true) {
                        this.setState({ otp_key: "" }, () => this.props.showSystemMessage('success', result.message))
                    } else {
                        this.props.showSystemMessage('error', result.message);
                    }
                } else {
                    this.props.showSystemMessage('error', result.errors.message);
                }
            });
    }

    handle2FaSetup = () => {
        this.setState({ show2FaSetup: !this.state.show2FaSetup });
    }

    handleTokenActivation = () => {
        this.setState({ show2FaSetup: false, show2FaSuccess: true });
    }

    closeTheSetup = () => {
        this.setState({ show2FaSetup: false });
    }

    render() {

        let modalContent;

        if (this.state.show2FaSetup) {
            modalContent =
                <Modal isOpen={this.state.show2FaSetup} toggle={this.handle2FaSetup}> <OTPSetup fromProfile={true} tokenVerified={this.handleTokenActivation} showSystemMessage={this.props.showSystemMessage} setupClosed={this.closeTheSetup} /></Modal>

        } else if (this.state.show2FaSuccess) {
            modalContent =
                <Modal isOpen={this.state.show2FaSuccess}>
                    <OTPSuccess fromProfile={true} />
                </Modal>
        }

        let authenticator = this.state.otp_key === "" || this.state.opt_key === null || !this.state.otp_key ?        

            (<React.Fragment>
                <span>Google Authenticator adds a layer of security to your account. This will protect your account from hackers even if your e-mail and password become compromised.</span> <br />
                { this.props.showEdit && <button className="btn btn-sm btn-primary mt-3" type="button" onClick={this.handle2FaSetup}>Set up Google Authenticator</button>}
                <div>
                    <a target="_blank" rel="noopener noreferrer" href="https://blog.maxbounty.com/2021/02/19/2fa-maxbounty-affiliate-account/">Read our 2FA set-up guide</a>
                </div> 
            </React.Fragment>)
            
        :

            (<React.Fragment>
                {this.props.showEdit ?
                    (this.state.showOTPcancelButton
                    ?
                        <button type="button" className="btn btn-sm btn-danger" name="cancel_otp" onClick={this.handle2FAcancel}>Deactivate Google Authenticator</button>
                    :
                        <button type="button" className="btn btn-sm btn-primary" onClick={this.toggleOTPcancelButton}>Google 2-fa Settings</button>) 
                :
                        <React.Fragment>Click edit to make changes.</React.Fragment>
                }
                <div>
                    <a target="_blank" rel="noopener noreferrer" href="https://blog.maxbounty.com/2021/02/19/2fa-maxbounty-affiliate-account/">Read our 2FA set-up guide</a>
                </div>
            </React.Fragment>)

        return (
            <div className="card my-2 max-width-750">
                {modalContent}
                <form
                    ref={this.form}
                    onSubmit={this.saveSettings}>
                    <div className="card-header d-flex align-items-center">
                        <div className="flex-1">2-Factor Authentication</div>                      
                    </div>
                    <div className="card-body p-0">
                        <table className="table table-card">
                            <tbody>
                                <tr>
                                    <td className="no-border">
                                        Google Authenticator
                                    </td>
                                    <td style={{ width: '70%' }} className="no-border">
                                        {authenticator}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </form>
            </div>
        );
    }
}

export default TwoFactorAuthentication;
