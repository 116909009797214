import React, { Component } from 'react';
import { Download, Layers } from 'react-feather';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';
import queryString from 'query-string';
import moment from 'moment';
import ReportEarnings from './ReportEarnings/ReportEarnings';
import ReportCreatives from './ReportCreatives/ReportCreatives';
import ReportSubid from './ReportSubid/ReportSubid';
import ReportLandingPage from './ReportLandingPage/ReportLandingPage';
import ReportClicks from './ReportClicks/ReportClicks';
import ReportConversions from './ReportConversions/ReportConversions';
import ReportReversals from './ReportReversals/ReportReversals';
import ReportReferrals from './ReportReferrals/ReportReferrals';
import Pagination from '../../component/UI/Pagination/Pagination';
import { CSVLink } from 'react-csv';

import './Report.css';
import '../../../node_modules/react-datepicker/dist/react-datepicker.css';

class Reports extends Component {

    _isMounted = false;

    // When selecting the report type, we need to be able to grab the value. We use the Ref for this.
    constructor(props) {
        super(props);
        this.reportSelect = React.createRef();
    }

    state = {
        dataLoaded: false,
        type: 'earnings',
        offerId: '',
        creativeId: '',
        landingPageId: '',
        subId: '',
        startDate: '',
        endDate: '',
        quickDate: 'month',
        selectedType: 'earnings',
        selectedStartDate: '',
        selectedEndDate: '',
        preparingCSV: false,
        CSVData: '',
        page: 1,
        totalPages: 1,
        resultsPerPage: 50
    }

    componentDidMount() {

        this._isMounted = true;
        let urlParams = queryString.parse(this.props.location.search);
        let offerId;
        let creativeId;
        let landingPageId;
        let subId;
        let startDate;
        let endDate;
        let quickDate;
        let type;
        let page;

        // Get the report type if specified. Get the offer ID if specified.
        this.props.type ? type = this.props.type : type = 'earnings';

        // Get the offer ID from the URL.
        offerId = urlParams.oid ? parseInt(urlParams.oid) : 0;

        // Get the creative ID from the URL.
        creativeId = urlParams.cid ? parseInt(urlParams.cid) : '';

        // Get the landing page ID from the URL.
        landingPageId = urlParams.lpid ? parseInt(urlParams.lpid) : '';

        // Get the affiliate ID from the URL.
        subId = urlParams.subid ? urlParams.subid : '';

        // Get the date from URL. If there's nothing, then use today.
        if (urlParams.startDate && urlParams.endDate) {
            startDate = urlParams.startDate;
            endDate = urlParams.endDate;
        } else {
            startDate = moment().startOf("month").format("YYYY-MM-DD");
            endDate = moment().endOf("month").format("YYYY-MM-DD");
        }

        /* Get the page number from the URL. If none, then use 1. NOTE: The API starts at page 0, so we'll need to subtract 1 before
           fetching from the API */
        if (urlParams.page) {
            page = parseInt(urlParams.page);
        } else {
            page = 1;
        }

        let startRow = (page - 1) * this.state.resultsPerPage;
        let endRow = (page * this.state.resultsPerPage);

        // See if the dates are one of the quick dates
        if (startDate === moment().startOf("month").format("YYYY-MM-DD") && endDate === moment().endOf("month").format("YYYY-MM-DD")) {
            quickDate = "month";
        } else if (startDate === moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD") && endDate === moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD")) {
            quickDate = "lastMonth";
        } else if (startDate === moment().startOf("day").format("YYYY-MM-DD") && endDate === moment().startOf("day").format("YYYY-MM-DD")) {
            quickDate = "today";
        } else if (startDate === moment().subtract(1, "d").startOf("day").format("YYYY-MM-DD") && endDate === moment().subtract(1, "d").startOf("day").format("YYYY-MM-DD")) {
            quickDate = "yesterday";
        } else if (startDate === moment().day(1).subtract(1, 'day').format('YYYY-MM-DD') && endDate === moment().day(1).add(5, 'days').format('YYYY-MM-DD')) {
            quickDate = 'thisPayPeriod';
        } else if (startDate === moment().day(1).subtract(8, 'days').format('YYYY-MM-DD') && endDate === moment().day(1).subtract(2, 'days').format('YYYY-MM-DD')) {
            quickDate = 'thisPayPeriod';
        } else {
            quickDate = "custom";
        }

        // Update the state with the chosen dates
        this.setState({
            dataLoaded: true,
            type: type,
            offerId: offerId,
            creativeId: creativeId,
            landingPageId: landingPageId,
            subId: subId,
            startDate: moment(startDate).startOf("day").format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment(endDate).startOf("day").format('YYYY-MM-DD HH:mm:ss'),
            quickDate: quickDate,
            selectedType: type,
            selectedStartDate: moment(startDate).startOf("day").format('YYYY-MM-DD HH:mm:ss'),
            selectedEndDate: moment(endDate).startOf("day").format('YYYY-MM-DD HH:mm:ss'),
            page: page,
            startRow: startRow,
            endRow: endRow
        });
    }

    handleChangeStart = date => {

        if (this._isMounted) {
            this.setState({
                selectedStartDate: date
            });
        }
    }

    handleChangeEnd = date => {

        if (this._isMounted) {
            this.setState({
                selectedEndDate: date
            });
        }
    }

    handleQuickDate = event => {
        let selectedStartDate = this.state.selectedStartDate;
        let selectedEndDate = this.state.selectedEndDate;
      
        switch (event.target.value) {
            case 'month':
                selectedStartDate = moment().startOf("month").format('YYYY-MM-DD')
                selectedEndDate = moment().endOf("month").format('YYYY-MM-DD')
                break;
            case 'lastMonth':
                selectedStartDate = moment().subtract(1, 'month').startOf("month").format('YYYY-MM-DD')
                selectedEndDate = moment().subtract(1, 'month').endOf("month").format('YYYY-MM-DD')
                break;
            case 'today':
                selectedStartDate = moment().startOf("day").format('YYYY-MM-DD')
                selectedEndDate = moment().endOf("day").format('YYYY-MM-DD')
                break;
            case 'yesterday':
                selectedStartDate = moment().subtract(1, 'day').startOf("day").format('YYYY-MM-DD')
                selectedEndDate = moment().subtract(1, 'day').endOf("day").format('YYYY-MM-DD')
                break;
            case 'thisPayPeriod':
                selectedStartDate = moment().day(1).subtract(1, 'day').format('YYYY-MM-DD');
                selectedEndDate = moment().day(1).add(5, 'days').format('YYYY-MM-DD');
                break;
            case 'lastPayPeriod':
                selectedStartDate = moment().day(1).subtract(8, 'days').format('YYYY-MM-DD');
                selectedEndDate = moment().day(1).subtract(2, 'days').format('YYYY-MM-DD');
                break;
            default:
        }

        if (this._isMounted) {
            this.setState({
                quickDate: event.target.value,
                selectedStartDate: selectedStartDate,
                selectedEndDate: selectedEndDate
            });
        }
    }

    handleChangeType = () => {

        if (this._isMounted) {
            this.setState({
                selectedType: this.reportSelect.current.value
            });
        }
    }

    handleTotalPages = (totalPages) => {

        if (this._isMounted) {
            this.setState({
                totalPages: totalPages
            });
        }
    }

    /* This will apply the settings based on the selected options */
    handleReport = () => {
        let startDate = moment(this.state.selectedStartDate).startOf("day").format('YYYY-MM-DD');
        let endDate = moment(this.state.selectedEndDate).startOf("day").format('YYYY-MM-DD');
        let type = this.state.selectedType;
        let offerId = this.state.offerId;
        let subId = this.state.subId;
        let creativeId = this.state.creativeId;
        let landingPageId = this.state.landingPageId;
        let page = 1;
        let startRow = (page - 1) * this.state.resultsPerPage;
        let endRow = (page * this.state.resultsPerPage) - 1;

        if (this._isMounted) {
            this.setState({
                startDate: startDate,
                endDate: endDate,
                type: type,
                page: page,
                startRow: startRow,
                endRow: endRow
            });
        }

        let url = `/reports/${type}?startDate=${startDate}&endDate=${endDate}`;

        if (offerId > 0) {
            url = url + `&oid=${offerId}`;
        }

        if (creativeId > 0) {
            url = url + `&cid=${creativeId}`
        }

        if (landingPageId > 0) {
            url = url + `&lpid=${landingPageId}`
        }

        if (subId > 0) {
            url = url + `&subid=${subId}`
        }

        if (type === "conversions" || type === "reversals") {
            url = url + `&page=${page}`
        }

        this.props.history.push(url);
    }

    handlePageChange = (requestedPage = this.state.page) => {
        let page = requestedPage;
        let startRow = (page - 1) * this.state.resultsPerPage;
        let endRow = (page * this.state.resultsPerPage) - 1;

        if (this._isMounted) {
            this.setState({
                page: page,
                startRow: startRow,
                endRow: endRow
            })
        }
    }

    prepareCSV = () => {

        if (this._isMounted) {
            this.setState({
                preparingCSV: true
            });
        }

        const mbToken = localStorage.getItem('mb-auth-token');
        let fetchUrl;
        let report;
        const realEndDate = moment(this.state.endDate).add(1, "d").format('YYYY-MM-DD');

        if (this.state.type === 'conversions') {
            report = 'conversions'
        } else if (this.state.type === 'reversals') {
            report = 'reversals'
        }

        fetchUrl = `${process.env.REACT_APP_API_URL}/reports/${report}?startDate=${this.state.startDate}&endDate=${realEndDate}&page=all`

        if (this.state.offerId) {
            fetchUrl = fetchUrl + `&oid=${this.state.offerId}`
        }

        if (this.state.creativeId) {
            fetchUrl = fetchUrl + `&cid=${this.state.creativeId}`;
        }

        if (this.state.landingPageId) {
            fetchUrl = fetchUrl + `&lpid=${this.state.landingPageId}`;
        }

        if (this.state.subId) {
            fetchUrl = fetchUrl + `&subid=${this.state.subId}`;
        }

        /* Get the affiliate object from the DB and populate the state information */
        fetch(fetchUrl, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': mbToken
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result["success"] === true) {
                        // Set the data for CSV download

                        if (this._isMounted) {
                            this.setState({
                                preparingCSV: false,
                                csvData: result
                            });
                        }
                    } else if (result["success"] === false) {
                        this.props.showSystemMessage("error", "There was an error fetching the data. Please try again.");
                    } else {
                        this.props.showSystemMessage("error", result.errors.message);
                    }
                })
            .catch(error => {
                console.log('Error in Reports: ', error);
                this.props.showSystemMessage("error", "Connection error.");
            });
    }

    handleDownloadData = (data) => {

        if (this._isMounted) {
            this.setState({
                csvData: data
            });
        }
    }

    getCSVLink = () => {
        if (this.state.csvData) {

            // Grab the required data
            let { report, totals } = this.state.csvData;

            // Create the array that will hold the CSV data
            let formattedData = [];

            // Create the headers for the CSV, add the data, add the totals
            switch (this.state.type) {
                case 'earnings':
                    // Headers
                    formattedData.push(['ID', 'Campaign', 'Clicks', 'Leads', 'CR', 'Sales', 'Earnings', 'EPC']);
                    // Data
                    report.map(row =>
                        formattedData.push([row.campaign_id, row.name, Intl.NumberFormat('en-GB').format(row.clicks), Intl.NumberFormat('en-GB').format(row.leads), parseFloat(row.conversion).toFixed(2), Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(row.sales), Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(row.earnings), parseFloat(row.epc).toFixed(2)])
                    );
                    // Totals
                    formattedData.push(['', '', Intl.NumberFormat('en-GB').format(totals.clicks), Intl.NumberFormat('en-GB').format(totals.leads), parseFloat(totals.conversion).toFixed(2), Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(totals.sales), Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(totals.earnings), parseFloat(totals.epc).toFixed(2)]);

                    break;
                case 'conversions':

                    // Headers
                    formattedData.push(['Date', 'Campaign', 'Status', 'CPA Type', 'Sales', 'Earnings', 'SUB-ID 1', 'SUB-ID 2', 'SUB-ID 3', 'SUB-ID 4', 'SUB-ID 5', 'IP Address', 'Country', 'Region', 'City', 'Device System', 'Device Type', 'Device OS'])
                    // Data
                    report.map(row =>
                        formattedData.push([moment.utc(row.date).format('YYYY-MM-DD HH:mm:ss'), row.name, row.status, row.rate_type, Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(row.sales), Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(row.earnings), row.subid1, row.subid2, row.subid3, row.subid4, row.subid5, row.ip_address, row.ip_location.country, row.ip_location.region, row.ip_location.city, row.device.system, row.device.device, row.device.os])
                    );

                    break;
                case 'clicks':
                    // Headers
                    formattedData.push(['Date', 'Clicks', 'Leads', 'CR', 'Sales', 'Earnings', 'EPC'])
                    // Data
                    report.map(row =>
                        formattedData.push([moment.utc(row.date).format('YYYY-MM-DD HH:mm:ss'), Intl.NumberFormat('en-GB').format(row.clicks), Intl.NumberFormat('en-GB').format(row.leads), parseFloat(row.conversion).toFixed(2), Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(row.sales), Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(row.earnings), parseFloat(row.epc).toFixed(2)])
                    );
                    // Totals
                    formattedData.push(['', Intl.NumberFormat('en-GB').format(totals.clicks), Intl.NumberFormat('en-GB').format(totals.leads), parseFloat(totals.conversion).toFixed(2), Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(totals.sales), Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(totals.earnings), parseFloat(totals.epc).toFixed(2)]);

                    break;
                case 'subid':
                    // Headers
                    formattedData.push(['SubID 1', 'Clicks', 'Leads', 'CR', 'Sales', 'Earnings', 'EPC'])
                    // Data
                    report.map(row =>
                        formattedData.push([row.subid1, Intl.NumberFormat('en-GB').format(row.clicks), Intl.NumberFormat('en-GB').format(row.leads), parseFloat(row.conversion).toFixed(2), Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(row.sales), Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(row.earnings), parseFloat(row.epc).toFixed(2)])
                    );
                    // Totals
                    formattedData.push(['', Intl.NumberFormat('en-GB').format(totals.clicks), Intl.NumberFormat('en-GB').format(totals.leads), parseFloat(totals.conversion).toFixed(2), Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(totals.sales), Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(totals.earnings), parseFloat(totals.epc).toFixed(2)]);

                    break;
                case 'creatives':
                    // Headers
                    formattedData.push(['Creative', 'Clicks', 'Leads', 'CR', 'Sales', 'Earnings', 'EPC'])
                    // Data
                    report.map(row =>
                        formattedData.push([row.creative_name, Intl.NumberFormat('en-GB').format(row.clicks), Intl.NumberFormat('en-GB').format(row.leads), parseFloat(row.conversion).toFixed(2), Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(row.sales), Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(row.earnings), parseFloat(row.epc).toFixed(2)])
                    );
                    // Totals
                    formattedData.push(['', Intl.NumberFormat('en-GB').format(totals.clicks), Intl.NumberFormat('en-GB').format(totals.leads), parseFloat(totals.conversion).toFixed(2), Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(totals.sales), Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(totals.earnings), parseFloat(totals.epc).toFixed(2)]);

                    break;
                case 'landingPages':
                    // Headers
                    formattedData.push(['Landing Page', 'Clicks', 'Leads', 'CR', 'Sales', 'Earnings', 'EPC']);
                    // Data
                    report.map(row =>
                        formattedData.push([row.landing_page, Intl.NumberFormat('en-GB').format(row.clicks), Intl.NumberFormat('en-GB').format(row.leads), parseFloat(row.conversion).toFixed(2), Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(row.sales), Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(row.earnings), parseFloat(row.epc).toFixed(2)])
                    );
                    // Totals
                    formattedData.push(['', Intl.NumberFormat('en-GB').format(totals.clicks), Intl.NumberFormat('en-GB').format(totals.leads), parseFloat(totals.conversion).toFixed(2), Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(totals.sales), Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(totals.earnings), parseFloat(totals.epc).toFixed(2)])

                    break;
                case 'reversals':
                    // Headers
                    formattedData.push(['Reverse Date', 'Lead Date', 'Status','Campaign ID', 'Campaign Name', 'CPA Type', 'Sales', 'Earnings', 'SUB-ID 1', 'SUB-ID 2', 'SUB-ID 3', 'SUB-ID 4', 'SUB-ID 5', 'IP Address']);
                    // Data
                    report.map(row =>
                        formattedData.push([moment.utc(row.reverse_date).format('YYYY-MM-DD HH:mm:ss'), moment.utc(row.lead_date).format('YYYY-MM-DD HH:mm:ss'), row.status, row.campaign_id, row.name, row.rate_type, Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(row.sales), Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(row.earnings), row.subid1, row.subid2, row.subid3, row.subid4, row.subid5, row.ip_address])
                    );

                    break;
                case 'referrals':
                    formattedData.push(['Date', 'Affiliates', 'Referral Income']);
                    break;

                default:
                    throw new Error("Invalid report type");
            }

            return <CSVLink className="btn btn-success btn-sm" data={formattedData} filename={`MaxBounty - ${this.state.type}.csv`}><Download size={14} /> Download CSV</CSVLink>

        } else if (!this.state.csvData && (this.state.type === 'conversions' || this.state.type === 'reversals')) {
            // There is no data in the csvData state yet and the type is conversions or reversals (uses paging)
            if (this.state.preparingCSV === true) {
                return <button className="btn btn-warning btn-sm" disabled><FontAwesomeIcon icon="spinner" spin /> Preparing CSV...</button>
            } else {
                return <button className="btn btn-warning btn-sm" onClick={this.prepareCSV}><Layers size={14} /> Prepare CSV</button>
            }
        }
    }

    getCancelUrl = (link, cancel, offerId, subId, creativeId, landingPageId, startDate, endDate) => {
        let url = `/reports/${link}?startDate=${startDate}&endDate=${endDate}`

        if (cancel === 'offer') {
            if (subId > 0) {
                url = url + `&subid=${subId}`
            }
            if (creativeId > 0) {
                url = url + `&cid=${creativeId}`;
            }
            if (landingPageId > 0) {
                url = url + `&lpid=${landingPageId}`;
            }
        }

        if (cancel === 'subId') {
            if (offerId > 0) {
                url = url + `&oid=${offerId}`;
            }
            if (creativeId > 0) {
                url = url + `&cid=${creativeId}`;
            }
            if (landingPageId > 0) {
                url = url + `&lpid=${landingPageId}`;
            }
        }

        if (cancel === 'creative') {
            if (offerId > 0) {
                url = url + `&oid=${offerId}`;
            }
            if (subId > 0) {
                url = url + `&subid=${subId}`
            }
            if (landingPageId > 0) {
                url = url + `&lpid=${landingPageId}`;
            }
        }

        if (cancel === 'landingPage') {
            if (offerId > 0) {
                url = url + `&oid=${offerId}`;
            }
            if (subId > 0) {
                url = url + `&subid=${subId}`
            }
            if (creativeId > 0) {
                url = url + `&cid=${creativeId}`;
            }
        }

        return url;

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {

        let reportToDisplay;

        switch (this.state.type) {
            case "earnings": reportToDisplay = <ReportEarnings
                key={`${this.state.startDate} - ${this.state.endDate}`}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                handleDownloadData={this.handleDownloadData}
                showSystemMessage={this.props.showSystemMessage} />
                break;
            case "creatives": reportToDisplay = <ReportCreatives
                key={`${this.state.startDate} - ${this.state.endDate}`}
                offerId={this.state.offerId}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                handleDownloadData={this.handleDownloadData}
                showSystemMessage={this.props.showSystemMessage} />
                break;
            case "subid": reportToDisplay = <ReportSubid
                key={`${this.state.startDate} - ${this.state.endDate}`}
                offerId={this.state.offerId}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                handleDownloadData={this.handleDownloadData}
                showSystemMessage={this.props.showSystemMessage} />
                break;
            case "landingPages": reportToDisplay = <ReportLandingPage
                key={`${this.state.startDate} - ${this.state.endDate}`}
                offerId={this.state.offerId}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                handleDownloadData={this.handleDownloadData}
                showSystemMessage={this.props.showSystemMessage} />
                break;
            case "conversions": reportToDisplay = <ReportConversions
                key={`${this.state.startDate} - ${this.state.endDate}`}
                offerId={this.state.offerId}
                creativeId={this.state.creativeId}
                landingPageId={this.state.landingPageId}
                subId={this.state.subId}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                startRow={this.state.startRow}
                endRow={this.state.endRow}
                getCancelUrl={this.getCancelUrl}
                handleTotalPages={this.handleTotalPages}
                showSystemMessage={this.props.showSystemMessage} />
                break;
            case "clicks": reportToDisplay = <ReportClicks
                key={`${this.state.startDate} - ${this.state.endDate}`}
                offerId={this.state.offerId}
                creativeId={this.state.creativeId}
                landingPageId={this.state.landingPageId}
                subId={this.state.subId}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                getCancelUrl={this.getCancelUrl}
                handleDownloadData={this.handleDownloadData}
                showSystemMessage={this.props.showSystemMessage} />
                break;
            case "reversals": reportToDisplay = <ReportReversals
                key={`${this.state.startDate} - ${this.state.endDate}`}
                offerId={this.state.offerId}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                startRow={this.state.startRow}
                endRow={this.state.endRow}
                handleDownloadData={this.handleDownloadData}
                handleTotalPages={this.handleTotalPages}
                showSystemMessage={this.props.showSystemMessage} />
                break;
            case "referrals": reportToDisplay = <ReportReferrals />
                break;
            default: reportToDisplay = <ReportEarnings
                key={`${this.state.startDate} - ${this.state.endDate}`}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                showSystemMessage={this.props.showSystemMessage} />
                break;
        }

        return (
            <div className="py-2">
                <div className="card">
                    <div className="card-header d-flex align-items-center">
                        <div className="flex-1">Reports</div>
                        <div>
                            {this.getCSVLink()}
                            {/* <button className="btn btn-success btn-sm" onClick={this.downloadCSV}><Download size={14} /> Download CSV</button>
                            { this.state.downloadData && <CSVDownload data={this.state.downloadData} target="_blank" /> } */}
                        </div>
                    </div>
                    <div className="card-body p-0">
                        <div className="row p-2">
                            <div className="col-md-3">
                                <select
                                    id="reportSelect"
                                    name="reportSelect"
                                    ref={this.reportSelect}
                                    className="custom-select custom-select-sm mr-2 my-1"
                                    onChange={this.handleChangeType}
                                    value={this.state.selectedType}>
                                    <option value="earnings">Earnings</option>
                                    <option value="conversions">Conversions</option>
                                    <option value="subid">SubID 1</option>
                                    <option value="reversals">Reversals</option>
                                    <option value="referrals">Referrals</option>
                                    {this.state.offerId &&
                                        /* These options are only available if there is an offer selected. */
                                        <React.Fragment>
                                            <option value="clicks">Clicks</option>
                                            <option value="creatives">Creatives</option>
                                            <option value="landingPages">Landing Pages</option>
                                        </React.Fragment>
                                    }
                                </select>
                            </div>
                            <div className="col-md-9 d-flex">
                                {this.state.selectedType !== 'referrals' &&
                                    <React.Fragment>
                                        <div className="mr-2 my-1">
                                            <select
                                                className="custom-select custom-select-sm"
                                                onChange={this.handleQuickDate}
                                                value={this.state.quickDate}>

                                                <option value="today">Today</option>
                                                <option value="yesterday">Yesterday</option>
                                                <option value="month">This Month</option>
                                                <option value="lastMonth">Last Month</option>
                                                <option value="thisPayPeriod">This Pay Period</option>
                                                <option value="lastPayPeriod">Last Pay Period</option>
                                                <option value="custom">Custom</option>
                                            </select>
                                        </div>
                                        {this.state.quickDate === 'custom' &&
                                            <React.Fragment>
                                                <div>
                                                    <DatePicker
                                                        selectsStart /* To signify it's the "start" picker */
                                                        selected={this.state.selectedStartDate ? moment(this.state.selectedStartDate).toDate() : null}
                                                        startDate={this.state.selectedStartDate ? moment(this.state.selectedStartDate).toDate() : null}
                                                        endDate={this.state.selectedEndDate ? moment(this.state.selectedEndDate).toDate() : null}
                                                        onChange={(date) => { this.handleChangeStart(date) }}
                                                        className="form-control form-control-sm display-inline-block mr-2 my-1"
                                                        todayButton={"Today"} />
                                                </div>
                                                <div className="d-flex mx-2 align-items-center">to</div>
                                                <div className="mr-2">
                                                    <DatePicker
                                                        selectsEnd /* To signify it's the "end" picker */
                                                        selected={this.state.selectedEndDate ? moment(this.state.selectedEndDate).toDate() : null}
                                                        startDate={this.state.selectedStartDate ? moment(this.state.selectedStartDate).toDate() : null}
                                                        endDate={this.state.selectedEndDate ? moment(this.state.selectedEndDate).toDate() : null}
                                                        onChange={(date) => { this.handleChangeEnd(date) }}
                                                        className="form-control form-control-sm display-inline-block mr-2 my-1"
                                                        todayButton={"Today"} />
                                                </div>
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                }
                                <div className="mr-2 my-1">
                                    <button
                                        onClick={() => this.handleReport()}
                                        className="btn btn-primary btn-sm">Run Report</button>
                                </div>
                            </div>
                        </div>
                        {(this.state.type === 'conversions' || this.state.type === 'reversals') &&
                            <Pagination
                                totalPages={this.state.totalPages}
                                currentPage={this.state.page}
                                handlePageChange={this.handlePageChange} />
                        }
                        <div className="table-responsive mb-reports">
                            {this.state.dataLoaded &&
                                /* Display the selected report */
                                reportToDisplay
                            }
                        </div>
                        {(this.state.type === 'conversions' || this.state.type === 'reversals') &&
                            <div className="mt-2">
                                <Pagination
                                    totalPages={this.state.totalPages}
                                    currentPage={this.state.page}
                                    handlePageChange={this.handlePageChange} />
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Reports;