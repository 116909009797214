import React, { Component } from 'react';

import { verifyToken, clearLocalStorageAndReload } from '../../../assets/JS/functions';

import './Macro.css';

class Macro extends Component {

    state = {
        helpText: ''
    }

    componentDidMount() {

        const authToken = localStorage.getItem('mb-auth-token');

        verifyToken().then(result => {
            if (result === true) {
                fetch(`${process.env.REACT_APP_API_URL}/application/help/18`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-app-token': authToken
                    }
                })
                    .then(res => res.json())
                    .then(result => {
                        if (result["success"] === true) {
                            this.setState({ helpText: result["help_text"] });
                        } else if (result["success"] === false) {
                            if (result.code !== 401) {
                                this.props.showSystemMessage('error', result.errors.message);
                            }
                        } else {
                            this.props.showSystemMessage('error', result.errors.message);
                        }
                    });
            } else {
                clearLocalStorageAndReload();
            }
        }).catch(error => {
            this.props.showSystemMessage('error', error.message);
        });
    }

    render() {
        return (
            <div className="Macro">
                <div className="modal-header">
                    <h6 className="modal-title">
                        Help
                </h6>
                    <button type="button" className="close" onClick={this.props.modalClosed}>
                        <span aria-hidden="true">x</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div dangerouslySetInnerHTML={{ __html: this.state.helpText }} />
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary btn-sm" onClick={this.props.modalClosed}>Close</button>
                </div>
            </div>
        );
    }
}
export default Macro;