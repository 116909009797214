import React, { Component } from 'react';
import StateList from '../../data/statelist.js';

class StatesProvinces extends Component {

    state = { 
        states: StateList.states
    }

    render() { 
        return ( 
            <React.Fragment>
                { this.state.states.map(state => <option key={state.value} value={state.value}>{state.name}</option>) }
            </React.Fragment>
        );
    }
}
 
export default StatesProvinces;