import React, { Component } from 'react';
import RightSideBarSection from './RightSideBarSection/RightSideBarSection';

import './RightSidebar.css';

class RightSideBar extends Component {

    state = {
        campaignCards: {           
            suggestionsFromAM: [],
            popularGamingOffers: []
        }
    }

    render() {
        return (
            <div className="RightSidebar">
                
                <RightSideBarSection for="recentlyapproved" showSystemMessage={this.props.showSystemMessage}/>

                {/* Ryan MacWha's picks */}
                <RightSideBarSection for="ampicks" showSystemMessage={this.props.showSystemMessage} affiliate={this.props.affiliate}/>

                <RightSideBarSection for="suggested" showSystemMessage={this.props.showSystemMessage} affiliate={this.props.affiliate}/>

                {/* <RightSideBarSection for="bookmarked" showSystemMessage={this.props.showSystemMessage} /> */}

                {/* Popular Category offers */}
                
            </div>
        );
    }
}

export default RightSideBar;
