import React, { Component } from 'react';
import { X, Delete, HelpCircle } from 'react-feather';
import Moment from 'react-moment';
import ReactTooltip from 'react-tooltip';

class MaxMoneyTransaction extends Component {

    state = {
        initiateCancel: false
    }

    componentWillUnmount(){
        ReactTooltip.hide();
    }

    initiateCancel = () => {
        this.setState({
            initiateCancel: true,
        })
    }

    getNotes = () => {
        if (this.props.data.notes !== "" && this.props.data.notes != null){
            return (
                <HelpCircle size="16" data-tip={this.props.data.notes} />
            )
        }
    }

    getCancelButton = (id) => {
        if (this.props.data.entry_type === "Redeem"){
            if (!this.state.initiateCancel){
                return (
                    <button
                        className="btn btn-link btn-sm"
                        style={{lineHeight: '1'}}
                        data-tip="Cancel"
                        onClick={this.initiateCancel}>
                        <X size="16" />
                    </button>
                );
            }else{
                return (
                    <button
                        className="btn btn-link btn-sm text-danger"
                        style={{lineHeight: '1'}}
                        data-tip="Are you sure?"
                        onClick={() => this.props.handleCancel(id)}>
                        <Delete size="16" />
                    </button>
                );
            }
        }
    }

    render() { 

        const transaction = this.props.data;
        let rowClass;        

        if (transaction.entry_type === "Redeemed"){
            rowClass = "text-success"

        } else if (transaction.entry_type === "Redeem"){
            rowClass = "text-warning"

        }

        return (
            <tr className={rowClass}>
                <td className="text-left font-italic"><Moment format="MMM DD">{transaction.entry_date}</Moment></td>
                <td className="text-left font-italic">{transaction.entry_type} {this.getNotes()}</td>
                <td className="text-right font-italic">{Intl.NumberFormat('en-GB', {minimumFractionDigits: 2}).format(transaction.amount)}</td>
                <td className="text-right font-italic">
                    { this.getCancelButton(transaction.entry_id) }                 
                </td>
                <ReactTooltip />
            </tr>
        );
    }
}
 
export default MaxMoneyTransaction;