import React, { Component } from 'react';

import queryString from 'query-string';

import TopCampaigns from './TopCampaigns/TopCampaigns';
import NewCampaigns from './NewCampaigns/NewCampaigns';
import TrendingCampaigns from './TrendingCampaigns/TrendingCampaigns';
import SuggestedCampaigns from './SuggestedCampaigns/SuggestedCampaigns';
import RecentlyViewed from './RecentlyViewed/RecentlyViewed';
import RecentlyApproved from './RecentlyApproved/RecentlyApproved';
import Bookmarked from './Bookmarked/Bookmarked';
import { Square, List } from 'react-feather';

import './Browse.css';

class Browse extends Component {

    state = {
        selectedTab: "new",
        selectedView: "gridView"
    }

    componentDidMount() {
        let tab, view;
        this.props.tab ? tab = this.props.tab : tab = "new";

        this.props.history.location.search ? view = queryString.parse(this.props.history.location.search).view : view = "gridView";
        this.setState({
            selectedTab: tab,
            selectedView: view
        });
    }

    tabClickHandler = (tabName) => {
        if (this.state.selectedTab !== tabName) {
            this.props.history.push(`/browse/${tabName}`);
        }
    }

    viewChangeHandler = (viewName) => {
        this.setState({ selectedView: viewName }, () => {
            this.props.history.replace(`/browse/${this.state.selectedTab}?view=${this.state.selectedView}`)
        });
    }

    render() {

        let tabToDisplay;
        let activeClasses = "nav-link nav-active btn btn-link";
        let nonActiveClasses = "nav-link btn btn-link";

        let activeViewIcon = "pl-2 active-view-icon";
        let nonActiveViewIcon = "pl-2";

        switch (this.state.selectedTab) {
            case "new": tabToDisplay = <NewCampaigns category="new" limit="30" showSystemMessage={this.props.showSystemMessage} view={this.state.selectedView} />
                break;
            case "top": tabToDisplay = <TopCampaigns category="top" limit="30" showSystemMessage={this.props.showSystemMessage} view={this.state.selectedView} />
                break;
            case "trending": tabToDisplay = <TrendingCampaigns category="trending" limit="30" showSystemMessage={this.props.showSystemMessage} view={this.state.selectedView} />
                break;
            case "suggested": tabToDisplay = <SuggestedCampaigns category="suggested" limit="30" showSystemMessage={this.props.showSystemMessage} view={this.state.selectedView} />
                break;
            case "recently-viewed": tabToDisplay = <RecentlyViewed category="recentlyviewed" limit="30" showSystemMessage={this.props.showSystemMessage} view={this.state.selectedView} />
                break;
            case "recently-approved": tabToDisplay = <RecentlyApproved category="recentlyapproved" limit="30" showSystemMessage={this.props.showSystemMessage} view={this.state.selectedView} />
                break;
            case "bookmarked": tabToDisplay = <Bookmarked category="bookmarked" limit="30" showSystemMessage={this.props.showSystemMessage} view={this.state.selectedView} />
                break;
            default: tabToDisplay = <NewCampaigns category="top" limit="30" showSystemMessage={this.props.showSystemMessage} view={this.state.selectedView} />
                break;
        }

        return (
            <div className="pt-2">
                <nav className="nav border rounded d-flex bg-white">
                    <button className={this.state.selectedTab === "new" ? activeClasses : nonActiveClasses} onClick={() => this.tabClickHandler("new")}>New Campaigns</button>
                    <button className={this.state.selectedTab === "top" ? activeClasses : nonActiveClasses} onClick={() => this.tabClickHandler("top")}>Top</button>
                    <button className={this.state.selectedTab === "trending" ? activeClasses : nonActiveClasses} onClick={() => this.tabClickHandler("trending")}>Trending</button>
                    <button className={this.state.selectedTab === "suggested" ? activeClasses : nonActiveClasses} onClick={() => this.tabClickHandler("suggested")}>Suggested for you</button>
                    <button className={this.state.selectedTab === "recently-viewed" ? activeClasses : nonActiveClasses} onClick={() => this.tabClickHandler("recently-viewed")}>Recently Viewed</button>
                    <button className={this.state.selectedTab === "recently-approved" ? activeClasses : nonActiveClasses} onClick={() => this.tabClickHandler("recently-approved")}>Recently Approved</button>
                    <button className={this.state.selectedTab === "bookmarked" ? activeClasses : nonActiveClasses} onClick={() => this.tabClickHandler("bookmarked")}>Bookmarked</button>
                </nav>
                <nav className="border rounded bg-white p-2 text-right"><span className="font-weight-bold" >View Mode: </span>
                    <Square size="28" fill="none" strokeWidth="3" className={this.state.selectedView === 'gridView' ? activeViewIcon : nonActiveViewIcon} onClick={() => this.viewChangeHandler('gridView')} />
                    <List size="28" fill="none" strokeWidth="3" className={this.state.selectedView === 'listView' ? activeViewIcon : nonActiveViewIcon} onClick={() => this.viewChangeHandler('listView')} />
                </nav>
                {tabToDisplay}
            </div>
        );
    }
}

export default Browse;