import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { User } from 'react-feather';
import { Link } from 'react-router-dom';
import './AccountDropdown.css';

class AccountDropdown extends Component {

    state = {
        dropdownOpen: false
    };

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    logout = () => {
        //localStorage.clear();
        localStorage.removeItem('mb-aid');
        localStorage.removeItem('mb-auth-token');
        localStorage.removeItem('mb-t');
        /* Finally, reload the page. */
        window.location.reload();
    }

    render() {
        return (
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle
                    tag="a"
                    onClick={this.toggle}
                    data-toggle="dropdown"
                    aria-expanded={this.state.dropdownOpen}
                    className="dropdown-toggle"
                >
                    <User className="feather-icon" width="20" height="20" viewBox="0 0 24 24" fill="none" strokeWidth="3" />
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem header className="font-weight-bold text-dark">Account</DropdownItem>
                    <DropdownItem divider className="m-0" />
                    <Link className="dropdown-news-item dropdown-item" to="/settings" onClick={this.toggle}>Settings</Link>
                    <DropdownItem divider className="m-0" />
                    <Link className="dropdown-news-item dropdown-item" to="/accountBalance" onClick={this.toggle}>Account Balances</Link>
                    <DropdownItem divider className="m-0" />
                    <Link className="dropdown-news-item dropdown-item" to="/globalPostback" onClick={this.toggle}>Global Postback</Link>                    
                    <DropdownItem divider className="m-0" />
                    <DropdownItem tag="a" href="#" className="dropdown-news-item" onClick={this.logout}>Logout</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    }
}
export default AccountDropdown;