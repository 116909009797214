import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import { X } from 'react-feather';

import './SystemMessage.css';

class SystemMessage extends Component {

    state = {
        in: false
    }

    componentDidMount() {
        this.setState({
            in: true
        })  
    }

    componentWillUnmount(){
        this.setState({
            in: false                
        })
    }

    render() { 

        let classes = "system-message d-flex align-items-center";

        if(this.props.type === 'success'){
            classes = classes + ' success';
        }else if (this.props.type === 'error'){
            classes = classes + ' error';
        }

        return (
            <CSSTransition
            in={this.state.in}
            timeout={200}
            classNames="systemMessageIn">
                <div className="system-message-container">
                    <div className="system-message-container-holder">
                        <div className={classes} onClick={this.props.hideSystemMessage}>
                            <div>{this.props.children}</div>
                            <div><span><X size="16" style={{marginTop:'-2px', marginLeft: "5px"}} /></span></div>
                        </div>
                    </div>
                </div>
            </CSSTransition>
        );
    }
}
 
export default SystemMessage;