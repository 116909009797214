import React, { Component } from 'react';

import './LandingPage.css';

class LandingPage extends Component {

    shouldComponentUpdate = (nextProps, nextState) => {
        return nextProps.classes !== this.props.classes;
    }

    landingPageClickHandler = (event) => {
        event.preventDefault();

        let landingId = event.currentTarget.getAttribute('lp-id');
        this.props.landingPageClicked(landingId);
        // console.log('You clicked the card');
    }

    render() {
        return (
            <div className={this.props.classes} lp-id={this.props.lpid}
                onClick={this.landingPageClickHandler}>
                <div className="mb-lp-image" style={{ backgroundImage: `URL(${this.props.backgroundImage})` }}>
                </div>
                <div className="text-center card-body" style={{ padding: "5px" }}>{this.props.text}</div>
            </div>
        );
    }
}
export default LandingPage;