import React from 'react';
import './HeaderTitle.css';
import maxmoneyStacked from '../../../assets/images/maxbounty_stacked.png'

const HeaderTitle = (props) => {
    return (
        <div className="mb-header-title">
            <div className="d-flex align-items-center">
                <img src={maxmoneyStacked} width="50" alt="maxmoney"/>
                <h4 className="px-2 m-0 bebas">{props.title}</h4>
            </div>
        </div>            
    );
}
 
export default HeaderTitle;