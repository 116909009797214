import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import maxbountyLogoSquare from '../../../assets/images/MaxBounty_Square.png';

import './OTPprompt.css';
import { verifyToken } from '../../../assets/JS/functions';

class OTPsetup extends Component {

    // this.props.pageReloaded() lifts the state up to App.js and tries to fetch aff details and sets states, this in turn reloads the page. This is done to bypass the browser prompt.

    state = {
        option: 'y'
    }

    // handle radio button options change
    handle2FaOptionsChange = (event) => {
        this.setState({ option: event.target.value });
    }

    // handle form submission
    handleSubmit = (event) => {
        event.preventDefault();
        verifyToken().then(result => {
            if (result === true) {
                this.props.optionChanged(this.state.option);
            } else {
                this.props.pageReloaded();
            }
        }).catch(error => {
            console.log('Error in verifying token: ', error);
            this.props.showSystemMessage('error', 'An internal error occurred. Please try again later');
        });
    }

    componentDidMount() {
        window.addEventListener('beforeunload', this.onUnload);
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('beforeunload', this.onUnload);
    }

    onUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
    }

    render() {

        return (
            <React.Fragment>
                <div className="panel">
                    <img className="mb-4 rounded" src={maxbountyLogoSquare} alt="" width="100" />
                </div>
                <div className="card text-left">
                    <div className="card-header font-weight-bold">
                        { this.props.p <= 2 ?
                            <span>Secure your account with Google 2-Factor Authentication.</span>
                        :
                            <span>Would you like to secure your account with Google 2-Factor Authentication?</span>
                        }
                        
                    </div>
                    <div className="card-body">
                        Google 2-fa adds a layer of security to your account. This will protect your account from hackers even if your e-mail and password become compromised.

                    <form onSubmit={this.handleSubmit}>
                        {this.props.p <= 2 && this.props.o !== 123 ?
                            <button className="btn btn-lg btn-primary btn-block mt-4" type="submit" >Set Up Now</button>
                        :
                            <React.Fragment>
                                <div className="custom-control custom-radio my-3 mx-5">
                                    <input className="custom-control-input" type="radio" name="otp_setup1" id="otp_setup1" value="y" checked={this.state.option === 'y'}
                                        onChange={this.handle2FaOptionsChange}
                                    />
                                    <label className="custom-control-label" htmlFor="otp_setup1">
                                        Yes, set up Google 2-fa
                                    </label>
                                </div>
                                <div className="custom-control custom-radio my-3 mx-5">
                                    <input className="custom-control-input" type="radio" name="otp_setup2" id="otp_setup2" value="n"
                                        onChange={this.handle2FaOptionsChange}
                                        checked={this.state.option === 'n'}
                                    />
                                    <label className="custom-control-label" htmlFor="otp_setup2">
                                        Not now (setup will still be available through your profile settings)
                                    </label>
                                </div>
                                <button className="btn btn-lg btn-primary btn-block mt-4" type="submit" >Proceed</button>
                            </React.Fragment>   
                        }                            
                        </form>  
                        <div className="text-center">
                            <a target="_blank" rel="noopener noreferrer" href="https://blog.maxbounty.com/2021/02/19/2fa-maxbounty-affiliate-account/">Read our 2FA set-up guide</a>
                        </div>                      
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(OTPsetup);