import React, { Component } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';

import { clearLocalStorageAndReload, verifyToken } from '../../assets/JS/functions';
import Loading from '../../component/UI/Loading/Loading';


class AccountBalance extends Component {

    constructor(props) {
        super(props);

        this.year = React.createRef();
        this.state = {
            dataLoaded: false,
            selectedYear: '',
            years: [],
            transactions: [],
            balanceIn: 0.00
        }
    }

    fetchAllData = () => {
        const mbToken = localStorage.getItem('mb-auth-token');
        fetch(`${process.env.REACT_APP_API_URL}/accountBalance/${this.state.selectedYear}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': mbToken
            }
        })
            .then(res => res.json())
            .then(result => {

                if (result["success"] === true) {
                    this.setState({ transactions: result.account_balances, years: result.available_years, dataLoaded: true }, () => {

                        if (!this.state.selectedYear) {

                            let initialStartingBalance = result.starting_balance_by_year[result.available_years[0]];

                            this.setState({ ...this.state, balanceIn: initialStartingBalance, selectedYear: result.available_years[0] });
                        } else {
                            this.setState({ ...this.state, balanceIn: result.starting_balance_by_year[this.state.selectedYear] });
                        }
                    });
                } else if (result["success"] === false) {
                    this.setState({ ...this.state, dataLoaded: true }, () => {
                        if (result.code !== 401) {
                            this.props.showSystemMessage("error", result.errors.message);
                        }
                    });
                }
            });
    }

    componentDidMount() {
        verifyToken().then(result => {
            if (result === true) {
                this.fetchAllData();
            } else {
                clearLocalStorageAndReload();
            }
        }).catch(error => {
            this.props.showSystemMessage("error", error.message);
        });
    }

    changeYear = () => {
        // Get data for the selected year
        let selected_year = this.year.current.value;
        this.setState({
            selectedYear: selected_year,
            dataLoaded: false
        }, () => {
            this.fetchAllData();
        });
    }

    render() {

        let balance = this.state.balanceIn;
        return (
            <React.Fragment>
                {this.state.dataLoaded ?

                    <div className="py-2">
                        <div className="card">
                            <div className="card-header">
                                Account Balances
                            </div>
                            <div className="card-body p-0">

                                <div className="d-flex m-3">
                                    <div className="d-flex align-items-center mr-3">
                                        Displaying statement for year:
                                    </div>
                                    <div>
                                        <select
                                            ref={this.year}
                                            defaultValue={this.state.selectedYear ? this.state.selectedYear : this.state.years[0]}
                                            className="custom-select"
                                            onChange={this.changeYear}>
                                            <option value="">Open this select menu</option>
                                            {this.state.years.map((year, i) => (
                                                <option value={year} key={i}>{year}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                <table className="table table-striped mt-3">
                                    <thead>
                                        <tr>
                                            <th scope="col">Date</th>
                                            <th scope="col">Type</th>
                                            <th scope="col" className="text-right">Amount</th>
                                            <th scope="col" className="text-right">Balance</th>
                                            <th scope="col">&nbsp;</th>
                                            <th scope="col">Notes</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.transactions.map((transaction, i) => {

                                            balance = balance + transaction.amount;                                            
                                            let amountClass = "text-right";

                                            if (transaction.amount < 0) {
                                                amountClass = amountClass + " text-danger";
                                            }

                                            let balanceClass = "text-right";
                                            if (balance.amount < 0) {
                                                balanceClass = balanceClass + " text-danger";
                                            }


                                            return (
                                                <tr key={i}>
                                                    <th scope="row">{<Moment format="D MMM YYYY" tz="UTC">{transaction.date}</Moment>}</th>
                                                    <td>{transaction.type}</td>
                                                    <td className={amountClass}>${Intl.NumberFormat("en-GB", { style: 'decimal', minimumFractionDigits: 2 }).format(transaction.amount)}</td>
                                                    <td className={balanceClass}>${Intl.NumberFormat("en-GB", { style: 'decimal', minimumFractionDigits: 2 }).format(balance)}</td>
                                                    <td>&nbsp;</td>
                                                    <td>{transaction.notes}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <div style={{ marginTop: '4rem' }}>
                        <Loading />
                    </div>}
            </React.Fragment>
        );
    }
}

export default AccountBalance;