import React, { Component } from 'react';
import ContactTimeList from '../../data/contacttime.js';

class ContactTime extends Component {

    state = {
        times: ContactTimeList.times
    }

    render() {
        return (
            <React.Fragment>
                {this.state.times.map(time => <option key={time.value} value={time.value}>{time.text}</option>)}
            </React.Fragment>
        );
    }
}

export default ContactTime;