import React, { Component } from 'react';
import { FaMoneyBill, FaEnvelope, FaWindowRestore, FaMobile, FaImage, FaSearch, FaDesktop, FaFacebook, FaGavel } from 'react-icons/fa';
import SearchResults from '../../Search/SearchResults/SearchResults';
import ReactTooltip from 'react-tooltip';

class Listview extends Component {
    render() {
        return (
            <section className="mt-2 rounded d-flex" style={{ backgroundColor: "white", padding: "0px", marginBottom: "10px" }}>
                <div className="table-responsive">
                    <table className="table table-striped table-card">
                        <thead>
                            <tr>
                                <th scope="col" className="text-center">
                                    ID
                                </th>
                                <th scope="col">&nbsp;</th>
                                <th scope="col" style={{ textAlign: "left", color: "black" }}>Campaign Name</th>
                                <th scope="col">CPA Type</th>
                                <th scope="col" className="text-right">CPA Rate</th>
                                <th scope="col" className="text-right">EPC</th>
                                <th scope="col" className="text-right">Traffic</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.campaigns.map(campaign => {
                                let rows = [];

                                if (campaign["incentive_traffic"] === 'Y') {
                                    rows.push(<span className="fa fa-fw" data-tip="Incentive" key="Incentive"><FaMoneyBill size={14} color="grey" /></span>)
                                }
                                if (campaign["email_traffic"] === 'Y') {
                                    rows.push(<span className="fa fa-fw" data-tip="Email" key="Email"><FaEnvelope size={14} color="grey" /></span>)
                                }
                                if (campaign["contextual_traffic"] === 'Y') {
                                    rows.push(<span className="fa fa-fw" data-tip="Contextual" key="Contextual"><FaWindowRestore size={14} color="grey" /></span>)
                                }
                                if (campaign["mobile_traffic"] === 'Y') {
                                    rows.push(<span className="fa fa-fw" data-tip="Mobile" key="Mobile"><FaMobile size={14} color="grey" /></span>)
                                }
                                if (campaign["native_traffic"] === 'Y') {
                                    rows.push(<span className="fa fa-fw" data-tip="Native" key="Native"><FaImage size={14} color="grey" /></span>)
                                }
                                if (campaign["search_traffic"] === 'Y') {
                                    rows.push(<span className="fa fa-fw" data-tip="Search" key="Search"><FaSearch size={14} color="grey" /></span>)
                                } if (campaign["display_traffic"] === "Y") {
                                    rows.push(<span className="fa fa-fw" data-tip="Display" key="Display"><FaDesktop size={14} color="grey" /></span>);
                                } if (campaign["social_media_traffic"] === "Y") {
                                    rows.push(<span className="fa fa-fw" data-tip="Social" key="brand bidding"><FaFacebook size={14} color="grey" /></span>);
                                } if (campaign["brand_bid_traffic"] === "Y") {
                                    rows.push(<span className="fa fa-fw" data-tip="Brand Bidding" key="Social"><FaGavel size={14} color="grey" /></span>);
                                }

                                return (<SearchResults
                                    key={campaign.campaign_id}
                                    campaignId={campaign.campaign_id}
                                    name={campaign.name}
                                    affiliateRate={campaign.rate_text}
                                    rateType={campaign.rate_type}
                                    epc={campaign.epc}
                                    allowedTrafficTypes={rows}
                                    thumbnail={campaign.thumbnail}
                                />)
                            })}
                        </tbody>
                    </table>
                    <ReactTooltip />
                </div>
            </section>
        );
    }
}

export default Listview;