import React from 'react';
import _ from 'lodash';

const Pagination = (props) => {

    const pages = _.range(1, props.totalPages + 1);

    function getClasses(page) {
        if (page === props.currentPage){
            return "page-item my-1 active"
        } else {
            return "page-item my-1"
        }
    }

    return (
        <nav className="mx-2" aria-label="Page navigation">
            <ul className="pagination flex-wrap">
                {pages.map((page, index) => (
                    <li className={getClasses(page)} key={index}><button className="page-link" onClick={() => props.handlePageChange(page)}>{page}</button></li>
                ))}
            </ul>
        </nav>
    );
}
 
export default Pagination;