import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import LandingPageEmpty from '../LandingPage/LandingPageEmpty/LandingPageEmpty';
import LandingPage from '../LandingPage/LandingPage';

import './TrackingSettings.css'

class TrackingSettings extends Component {

    constructor(props) {
        super(props);

        this.sub1 = React.createRef();
        this.sub2 = React.createRef();
        this.sub3 = React.createRef();
        this.sub4 = React.createRef();
        this.sub5 = React.createRef();
    }

    state = {
        subIds: {
            sub1: '',
            sub2: '',
            sub3: '',
            sub4: '',
            sub5: ''
        },
        activeCard: this.props.active
    }

    landingPages = this.props.landingPages;

    componentWillMount() {
        // We create a variable that will hold the setTimeout function.
        this.timer = null;
    }

    subidChangeHandler = (event) => {
        event.preventDefault();
        // Every time this function is called, the timer will be reset, indicating the user is not done typing.
        clearTimeout(this.timer);

        // We must store the values in variables, as event.currentTarget won't exist anymore when calling the callback function.
        const subIds = {
            sub1: this.sub1.current.value,
            sub2: this.sub2.current.value,
            sub3: this.sub3.current.value,
            sub4: this.sub4.current.value,
            sub5: this.sub5.current.value
        }

        // We set the timer. If it elapses without this function being called again, then we will make a request to the server.
        this.timer = setTimeout(() => this.triggerSubidChange(subIds), 500)
    }

    triggerSubidChange = (subIds) => {
        this.setState({
            subIds: subIds
        }, () => {
            //Onchange, it will update the state of the parent immediately.
            this.props.submitted(this.state.subIds);
        });
    }

    landingPageIdHandler = (lPageId) => {
        // event.preventDefault();
        this.props.clicked(lPageId);
        this.setState({ ...this.state, activeCard: lPageId });
    }

    render() {

        let monthStartDate = moment().startOf("month").format("YYYY-MM-DD");
        let monthEndDate = moment().endOf("month").format("YYYY-MM-DD");

        return (
            <div>
                {/* BackgroundImage, title, lpid will be provided through props */}
                <hr />
                <div className="py-3">
                    <h5 className="pb-2 d-flex">
                        <strong>3</strong>
                        <div style={{ paddingLeft: "8px" }}>Select landing page</div>
                    </h5>
                    <div className="d-flex flex-wrap">

                        {
                            this.landingPages.map((landingPage) => (
                                <LandingPage
                                    key={landingPage.landing_page_id}
                                    lpid={landingPage.landing_page_id}
                                    backgroundImage={landingPage.thumbnail}
                                    text={landingPage.name}
                                    active={landingPage.landing_page_id === this.state.activeCard ? true : false}
                                    landingPageClicked={this.landingPageIdHandler}
                                    classes={landingPage.landing_page_id === Number(this.state.activeCard) ? "lpSelect mb-lp-card card active" : "lpSelect mb-lp-card card"} />
                            ))}

                        <LandingPageEmpty></LandingPageEmpty>
                        <LandingPageEmpty></LandingPageEmpty>
                        <LandingPageEmpty></LandingPageEmpty>
                        <LandingPageEmpty></LandingPageEmpty>
                        <LandingPageEmpty></LandingPageEmpty>
                    </div>
                </div>

                {/* The part below remains static: there are always going to be 5 form fields to enter sub IDs */}

                <hr />
                <div className="py-3">
                    <h5 className="pb-2 d-flex">
                        <strong>4</strong>
                        <div style={{ paddingLeft: "8px" }}>Sub IDs (optional)</div>
                    </h5>
                    <form>

                        <div className='alert alert-secondary'>
                            <div className='text-secondary subcode-text'>Sub ID 1 should be used to group your traffic sources. You can then use the <Link to={`/reports/subid?startDate=${monthStartDate}&endDate=${monthEndDate}`}> subid report </Link>to optimize your traffic.</div>
                            <div className="row pt-2 form-inline">
                                <label htmlFor="sub1" className="col-sm-2 form-inline-label">Sub ID 1:</label>
                                <div className="col-sm-10">
                                    <input ref={this.sub1} type="text" className="form-control" name="sub1" value={this.state.sub1} onChange={this.subidChangeHandler} />
                                </div>
                            </div>
                        </div>

                        <div className='alert alert-secondary'>
                            <div className='text-secondary subcode-text'>Sub IDs 2-5 can be used for your other tracking needs. Details can be viewed by downloading the CSV from the <Link to={`/reports/conversions?startDate=${monthStartDate}&endDate=${monthEndDate}&page=1`}>conversion report</Link>.</div>
                            <div className="form-inline row pt-2">
                                <label htmlFor="sub1" className="col-sm-2 form-inline-label">Sub ID 2:</label>
                                <div className="col-sm-10">
                                    <input ref={this.sub2} type="text" className="form-control" name="sub2" value={this.state.sub2} onChange={this.subidChangeHandler} />
                                </div>
                            </div>
                            <div className="form-inline row pt-2">
                                <label htmlFor="sub1" className="col-sm-2 form-inline-label">Sub ID 3:</label>
                                <div className="col-sm-10">
                                    <input ref={this.sub3} type="text" className="form-control" name="sub3" value={this.state.sub3} onChange={this.subidChangeHandler} />
                                </div>
                            </div>
                            <div className="form-inline row pt-2">
                                <label htmlFor="sub1" className="col-sm-2 form-inline-label">Sub ID 4:</label>
                                <div className="col-sm-10">
                                    <input ref={this.sub4} type="text" className="form-control" name="sub4" value={this.state.sub4} onChange={this.subidChangeHandler} />
                                </div>
                            </div>
                            <div className="form-inline row pt-2">
                                <label htmlFor="sub1" className="col-sm-2 form-inline-label">Sub ID 5:</label>
                                <div className="col-sm-10">
                                    <input ref={this.sub5} type="text" className="form-control" name="sub5" value={this.state.sub5} onChange={this.subidChangeHandler} />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
export default TrackingSettings;