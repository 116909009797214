import React, { Component } from 'react';
import LazyLoad from 'react-lazyload';

import CampaignSet from '../../component/CampaignSet/CampaignSet';
import CampaignCategoryItem from './CampaignCategoryItem/CampaignCategoryItem';
import UserStats from '../../component/UserStats/UserStats';
import Sliders from './Sliders/Sliders';

import { clearLocalStorageAndReload, verifyToken } from '../../assets/JS/functions';
import './Dashboard.css';


class Dashboard extends Component {

    state = {
        showIntroSurvey: false
    }

    componentDidMount() {

        verifyToken().then(result => {
            if (result === false) {
                clearLocalStorageAndReload();
            }
        }).catch(error => {
            console.log('error at Dashboard: ', error);
            this.props.showSystemMessage("error", "Error while verifying token.");
        });
    }

    render() {
        return (
            <React.Fragment>
                <UserStats affiliate={this.props.affiliate} showSystemMessage={this.props.showSystemMessage} />
                <div className="no-mobile"><Sliders /></div>

                <section className="mb-dashboard-offers">
                    <CampaignCategoryItem campaignCategory="Popular Campaigns" />
                    <CampaignSet category="popular" limit="6" page="0" showSystemMessage={this.props.showSystemMessage} />
                    <hr />

                    <CampaignCategoryItem campaignCategory="New Campaigns" />
                    <CampaignSet category="new" limit="6" page="0" showSystemMessage={this.props.showSystemMessage} />
                    <hr />

                    <CampaignCategoryItem campaignCategory="Suggested For You" />
                    <LazyLoad>
                        <CampaignSet category="suggested" limit="6" page="0" showSystemMessage={this.props.showSystemMessage} />
                    </LazyLoad>
                </section>
                {/* <Modal isOpen={this.state.showIntroSurvey} toggle={this.modalCloseHandler} className="modal-lg">
                    <IntroModal />
                </Modal> */}
            </React.Fragment>
        );
    }
}
export default Dashboard;