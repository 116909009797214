import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import queryString from 'query-string';
import Moment from 'react-moment';

import maxbountyLogoSquare from '../../assets/images/MaxBounty_Square.png';
import dotPattern from '../../assets/images/dot-pattern.png';
import loginVideo from '../../assets/images/login.mp4';

import './Verify.css'

class Verify extends Component {

    state = { 
        dataLoaded: false,
        status: 0
    }

    async componentDidMount(){

        let urlParams = queryString.parse(this.props.location.search);
        let email = urlParams.e;
        let affiliateId = urlParams.i

        if (email !== undefined && email !== null && affiliateId !== undefined && affiliateId !== null && !isNaN(affiliateId)){
            await this.verifyAffiliate(affiliateId, email);
        }

    }

    verifyAffiliate = async (affiliateId, email) =>{
        await fetch(`${process.env.REACT_APP_API_URL}/verifyRegistration?i=${affiliateId}&e=${email}`, {
            method: 'GET',
            headers:{
                'Content-Type': 'application/json',
            }
        })
            .then(res => res.json())
            .then(
                (result) => {

                    if (result["success"] === true){                                                
                        this.setState({
                            dataLoaded: true,
                            status: result["status"],
                            affiliateManager: result["affiliateManager"],
                            list: result["list"],
                            businessHours: result["businessHours"],
                            currentTime: result["currentTime"],
                            dayOfWeek: result["dayOfWeek"],
                            affiliateId: affiliateId
                        }) 
                        return true;
                    } else{                        
                        this.setState({
                            dataLoaded: true,
                            status: 0
                        })
                        return false;
                    }                    
                                        
                },
                (error) => {                    
                    this.props.showSystemMessage("error", "Connection error.")
                    this.setState({
                        dataLoaded:true,
                        verified: 0
                    })
                    return false;
                }
            )
    }

    render() {        

        return ( 
            <section className="login-container text-center d-flex align-items-center" style={{background:'RGBA(0,0,0,0.9)'}}>

                {/* Background Video */}
                <div style={{backgroundImage:`URL(${dotPattern})`, backgroundRepeat:'repeat', height:'100%', width:'100%', position:'absolute', zIndex:'9'}}></div>
                <video autoPlay muted loop className="login-video">
                    <source src={loginVideo} type="video/mp4"/>
                </video>                    

                <div className="verify-container">
                    <img className="mb-4 rounded" src={maxbountyLogoSquare} alt="MaxBounty" width="100"/>
                    <div className="card">
                        <div className="card-body text-center">
                            { this.state.dataLoaded ? 
                                <React.Fragment>
                                    { this.state.status === 2 ?
                                        // Affiliate was pending or validating
                                        <React.Fragment>
                                            <div class="alert alert-success">Your account has been verified!</div>
                                            <p><span className="font-weight-bold">Wait!</span> There are 2 steps left to the application process.</p>
                                            <hr></hr>
                                            <div className="text-left">                            
                                                <h4>1. Application Review</h4>
                                                <p>Your application will be reviewed by our compliance and affiliate management teams. Once your application has been reviewed, you should be contacted for a telephone interview.</p>
                                                <h4>2. Phone Interview</h4>
                                                <p>All applicants <span className="font-weight-bold">MUST</span> complete a telephone interview with a MaxBounty affiliate manager.</p>
                                                { !this.state.list &&
                                                    // The affiliate is from one of the blacklisted countries, or is low experience
                                                    <React.Fragment>
                                                        <p>If you would like to speed up the review of your application, please feel free to contact your affiliate manager anytime between 9:00 AM and 4:00 PM Eastern Time, Monday to Friday.</p>
                                                        { this.state.businessHours ?
                                                            <p>The current time at MaxBounty is <Moment format="h:mm">{this.state.currentTime}</Moment>, Eastern Time on <Moment format="dddd">{this.state.currentTime}</Moment>.</p>
                                                        :
                                                            <p>Call us now, we are open!</p>
                                                        }
                                                        <hr></hr>
                                                        <div>
                                                            <p className="font-weight-bold">Your affiliate manager is:</p>
                                                            <p>{this.state.affiliateManager.name}<br></br>
                                                            Phone: {this.state.affiliateManager.phone}<br></br>
                                                            Skype: {this.state.affiliateManager.skype}<br></br>
                                                            Your affiliate ID is: {this.state.affiliateId}</p>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </div>
                                        </React.Fragment>
                                    : this.state.status === 1 ?
                                        // Affiliate was active already
                                        <React.Fragment>
                                            <p>Your email address has been confirmed and your account is active.</p>
                                            Please <Link to="/login">login</Link> to your account.
                                        </React.Fragment>
                                    : 
                                        <React.Fragment>The confirmation link you used is invalid. Please review the confirmation e-mail you received and try again.</React.Fragment>
                                    }
                                </React.Fragment>
                            :
                                <React.Fragment>
                                    <FontAwesomeIcon icon="spinner" spin /> Verifying your application...
                                </React.Fragment>
                            }                            
                        </div>
                    </div>                    
                </div>

            </section>
        );
    }
}
 
export default Verify;