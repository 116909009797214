import React, { Component } from 'react';
import { Prompt } from 'react-router';

import Timezones from '../../../component/FormElements/Timezones';
import ContactTime from '../../../component/FormElements/ContactTime';

import TimezoneList from '../../../data/timezonelist.js';
import './PersonalInformation.css';

class PersonalInformation extends Component {

    state = {
        editing: false,
        personalInfo: {
            name: this.props.personalInfo.name,
            company: this.props.personalInfo.company,
            mobile: this.props.personalInfo.mobile_phone,
            phone: this.props.personalInfo.phone,
            timezone: {
                value: this.props.personalInfo.time_zone,
                text: TimezoneList.timezones.filter(timezone => timezone.value === this.props.personalInfo.time_zone)[0] ? TimezoneList.timezones.filter(timezone => timezone.value === this.props.personalInfo.time_zone)[0].name : ''
            },
            contact_time: {
                value: this.props.personalInfo.contact_time.value,
                text: this.props.personalInfo.contact_time.text
            },
            im_handle: this.props.personalInfo.im_handle
        },
        phoneTemp: this.props.personalInfo.phone,
        mobileTemp: this.props.personalInfo.mobile_phone,
        affiliateFlags: this.props.affiliateFlags,
        isFormHalfFilled: false
    }

    setEditing = (e) => {
        e.preventDefault();
        this.setState({
            editing: !this.state.editing,
            personalInfo: this.state.personalInfo,
            isFormHalfFilled: false
        });
    }

    inputChangeHandler = (e) => {
        e.preventDefault();
        e.target.setCustomValidity('');
        e.target.reportValidity();
        this.setState({
            isFormHalfFilled: true
        });
    }

    phoneChangeHandler = (event, input) => {
        if (input === 'phone') {
            this.setState({ ...this.state, phoneTemp: event, isFormHalfFilled: true })
        } else if (input === 'mobile') {
            this.setState({ ...this.state, mobileTemp: event, isFormHalfFilled: true })
        }
    }

    validatePhone = async (mobile) => {
        const authToken = localStorage.getItem('mb-auth-token');

        return await fetch(`${process.env.REACT_APP_API_URL}/affiliate/validatePhone?m=${mobile}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authToken
            }
        })
            .then(res => res.json())
            .then(result => {
                if (result["success"] === true) {
                    return result.isValid;
                } else {
                    return false;
                }
            })
            .catch(error => {
                console.log('error: ', error);
            });
    }

    saveSettings = async (e) => {
        e.preventDefault();
        e.persist();
        /* Do form submission */

        /* Edit the parent state for NAME. We do this because there are a few variables we store at the application level:
            -affiliate name
            -maxmoney
        */

        try {
            const validatePhone = await this.validatePhone(this.state.mobileTemp);
            if (!validatePhone) {
                this.props.showSystemMessage('error', 'Invalid mobile number');
                return;
            }
        } catch (error) {
            console.log('Error while validating phone number: ', error);
        }

        this.props.editName(e.target.name.value);

        this.props.editMobile(this.state.mobileTemp);

        this.setState({
            // editing: !this.state.editing,
            isFormHalfFilled: false,
            personalInfo: {
                name: e.target.name.value,
                company: e.target.company.value,
                mobile: this.state.mobileTemp,
                phone: this.state.phoneTemp,
                timezone: {
                    value: e.target.timezone.value,
                    text: e.target.timezone.options[e.target.timezone.selectedIndex].text
                },
                contact_time: {
                    value: e.target.contact_time.value,
                    text: e.target.contact_time.options[e.target.contact_time.selectedIndex].text
                },
                im_handle: e.target.im_handle.value
            }
        }, () => {
            const authToken = localStorage.getItem("mb-auth-token");

            fetch(`${process.env.REACT_APP_API_URL}/affiliate/personalDetails`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-app-token': authToken
                },
                body: JSON.stringify({
                    name: this.state.personalInfo.name,
                    company: this.state.personalInfo.company,
                    mobile: this.state.personalInfo.mobile,
                    phone: this.state.personalInfo.phone,
                    timezone: this.state.personalInfo.timezone.value,
                    contact_time: this.state.personalInfo.contact_time.value,
                    im_handle: this.state.personalInfo.im_handle
                })
            })
                .then(res => res.json())
                .then(result => {
                    if (result["success"] === true) {
                        // If this is success then flag 121 should be good to be removed
                        this.setState({ editing: false }, () => {
                            return this.props.showSystemMessage("success", result.message)
                        });
                    } else if (result["success"] === false) {

                        // Restore the state to props 

                        this.setState({
                            personalInfo: {
                                name: this.props.personalInfo.name,
                                company: this.props.personalInfo.company,
                                mobile: this.props.personalInfo.mobile_phone,
                                phone: this.props.personalInfo.phone,
                                timezone: {
                                    value: this.props.personalInfo.time_zone,
                                    text: TimezoneList.timezones.filter(timezone => timezone.value === this.props.personalInfo.time_zone)[0].name
                                },
                                contact_time: {
                                    value: this.props.personalInfo.contact_time.value,
                                    text: this.props.personalInfo.contact_time.text
                                },
                                im_handle: this.props.personalInfo.im_handle
                            }
                        }, () => { this.props.showSystemMessage("error", result.errors.message) })
                    }
                })
                .catch(error => {
                    this.props.showSystemMessage("error", error.message);
                });
        });
    }

    render() {
        return (
            <div className="card my-2 max-width-750 PersonalInfo">
                <Prompt
                    when={this.state.isFormHalfFilled}
                    message="Are you sure you want to leave? Changes you made may not be saved."
                />
                <form
                    onSubmit={this.saveSettings}>
                    <div className="card-header d-flex align-items-center">
                        <div className="flex-1">Personal Information</div>
                        {this.props.showEdit &&
                            <div className="text-right">
                                {this.state.editing ?
                                    <React.Fragment>
                                        <button className="btn btn-sm btn-outline-danger mr-2" type="button" onClick={this.setEditing}>Cancel</button>
                                        <button className="btn btn-success btn-sm" type="submit">Save</button>
                                    </React.Fragment>
                                    :
                                    <button className="btn btn-primary btn-sm" onClick={this.setEditing}>Edit</button>
                                }
                            </div>}
                    </div>
                    <div className="card-body p-0">
                        <table className="table table-card">
                            <tbody>
                                <tr>
                                    <td className="no-border">Name</td>
                                    <td style={{ width: '70%' }} className="no-border">
                                        {this.state.editing === false ?
                                            <div>{this.state.personalInfo.name}</div>
                                            :
                                            <input
                                                name="name"
                                                className="form-control"
                                                type="text"
                                                defaultValue={this.state.personalInfo.name}
                                                onChange={this.inputChangeHandler}
                                                required>
                                            </input>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Company</td>
                                    <td>
                                        {this.state.editing === false ?
                                            <div>{this.state.personalInfo.company}</div>
                                            :
                                            <input
                                                name="company"
                                                className="form-control"
                                                type="text"
                                                defaultValue={this.state.personalInfo.company}
                                                onChange={this.inputChangeHandler}>
                                            </input>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Time Zone</td>
                                    <td>
                                        {this.state.editing === false ?
                                            <div>
                                                {this.state.personalInfo.timezone.text}
                                            </div>
                                            :
                                            <select
                                                name="timezone"
                                                defaultValue={this.state.personalInfo.timezone.value}
                                                className="form-control"
                                                onChange={this.inputChangeHandler}
                                                required>
                                                <option value=""></option>
                                                <Timezones />
                                            </select>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Best time to contact you</td>
                                    <td>
                                        {this.state.editing === false ?
                                            <div>{this.state.personalInfo.contact_time.text}</div>
                                            :
                                            <select
                                                name="contact_time"
                                                defaultValue={this.state.personalInfo.contact_time.value}
                                                className="form-control"
                                                onChange={this.inputChangeHandler}
                                                required>
                                                <option value=""></option>
                                                <ContactTime />
                                            </select>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Skype Handle</td>
                                    <td>
                                        {this.state.editing === false ?
                                            <div>{this.state.personalInfo.im_handle}</div>
                                            :
                                            <input
                                                name="im_handle"
                                                className="form-control"
                                                type="text"
                                                defaultValue={this.state.personalInfo.im_handle}
                                                onChange={this.inputChangeHandler}
                                                >
                                            </input>
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </form>
            </div>
        );
    }
}

export default PersonalInformation;