import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import maxbountyLogoSquare from '../../../assets/images/MaxBounty_Square.png';

class OTPcheck extends Component {

    state = {
        code: 0
    }

    // On Cancel, go back to login page.
    cancelOTPcheck = (e) => {
        e.preventDefault();
        window.location.reload();
    }

    // function to store input in a state as user types in
    handleCodeInput = (e) => {
        e.preventDefault();
        this.setState({ code: e.target.value });
    }

    handleCodeSubmit = (e) => {
        e.preventDefault();

        // Send entered code to back end for verification
        const authtoken = localStorage.getItem('mb-auth-token');

        fetch(`${process.env.REACT_APP_API_URL}/authenticationApp/otpVerify`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authtoken
            },
            body: JSON.stringify({
                inputCode: this.state.code
            })
        })
            .then(res => res.json())
            .then(result => {

                if (result["success"]) {
                    if (result.isCodeValid) {   // if verified, log in
                        this.props.otpVerified();
                    } else {
                        this.props.showSystemMessage('error', 'The code you entered is incorrect. Please try again.');
                    }
                } else {    // if not, prompt error and re-display the code enter page
                    this.props.showSystemMessage('error', result.errors.message);
                }
            })
            .catch(error => error);
    }

    render() {
        return (
            <form className="form-signin" method="post" autoComplete="off" onSubmit={this.handleCodeSubmit}>
                <div className="panel">
                    <img className="mb-4 rounded" src={maxbountyLogoSquare} alt="" width="100" />
                </div>
                <p className="font-italic text-white pb-2">Enter the 6 digit code from Google Authenticator.</p>
                <input name="otp" className="form-control mb-3" autoFocus type="text" required style={{ borderRadius: '4px' }} onChange={this.handleCodeInput} />
                <button className="btn btn-primary btn-block" type="submit">Submit</button>
                <button className="btn btn-link" onClick={this.cancelOTPcheck}>Cancel</button>
                <p className="mt-5 mb-3 text-muted">MaxBounty Inc.</p>
            </form>
        );
    }
}

export default withRouter(OTPcheck);