import React, { Component } from 'react';

class FormExperience extends Component {

    state = {
        showOtherTextbox: this.props.values.hear_about_source === 'other' ? true : false
    }

    constructor(props) {
        super(props);
        this.formExperience = React.createRef();
        this.hear_about_source = React.createRef();
        this.hear_about = React.createRef();
        this.experience_level = React.createRef();
        this.experience_description = React.createRef();
        this.promotion = React.createRef();
        this.desired_offer = React.createRef();
        this.website = React.createRef();        
    }

    validate = () => {

        /* Check required fields */
        const input_hear_about = this.hear_about.current; // This is the optional input
        const input_hear_about_source = this.hear_about_source.current; // This is the new dropdown
        const input_experience_level = this.experience_level.current;
        const input_experience_description = this.experience_description.current;
        const input_promotion = this.promotion.current;
        const input_website = this.website.current ? this.website.current : "Not Applicable";        

        /* Reset any previous custom validity settings to valid */
        input_experience_description.setCustomValidity('');
        input_promotion.setCustomValidity('');

        if ((input_hear_about_source.value ==="other" && input_hear_about && input_hear_about.checkValidity() === false) || input_hear_about_source.checkValidity() === false || input_experience_level.checkValidity() === false || input_experience_description.checkValidity() === false || input_promotion.checkValidity() === false || input_website.checkValidity() === false) {
            return false
        } else if (input_hear_about && input_hear_about.value.length > 50) {
            input_hear_about.setCustomValidity('Must be less than 50 characters');
        } else if (input_hear_about_source.value === "other" && (!input_hear_about || input_hear_about.value === "")) {
            input_hear_about.setCustomValidity('You must provide a description of your source.');
        } else if (input_experience_description.value.length < 50 || input_experience_description.value.length > 200) {
            input_experience_description.setCustomValidity('Your description must be between 50-200 characters.');
        } else if (input_promotion.value.length < 50 || input_promotion.value.length > 200) {
            input_promotion.setCustomValidity('Your description must be between 50-200 characters.');
        } else {
            return true
        }
    }

    handleHearAboutChange = (source, e) => {
        e.preventDefault();
        this.props.handleChange(source)(e);
        if (source === 'hear_about_source') {
            if (e.target.value === 'other') {
                this.setState({ showOtherTextbox: true });
            } else {
                this.setState({ showOtherTextbox: false });
            }
        }
    }

    saveAndContinue = async (e) => {
        e.preventDefault()
        if (this.validate()) {
            let name = this.props.values.firstname + " " + this.props.values.lastname;
            await this.props.trackApplication(this.props.appData.appToken, this.props.values.email, 'Experience', this.props.values.company, name, this.props.values.address_1, this.props.values.address_2, this.props.values.city, this.props.values.state, this.props.values.country, this.props.values.zipcode, this.props.values.phone, this.props.values.mobile, this.props.values.im_handle, this.props.values.experience_description, this.props.values.experience_level)
            this.props.nextStep()
        } else {
            /* Forces html5 form validation */
            const formExperience = this.formExperience.current;
            formExperience.reportValidity();
        }
    }

    back = (e) => {
        e.preventDefault()
        this.props.prevStep();
    }

    render() {

        const { values } = this.props

        return (
            <form ref={this.formExperience}>
                <div className="form-group mb-3 text-white text-left">
                    <label htmlFor="hear_about">How did you hear about MaxBounty?*</label>
                    <select className='form-control'
                        ref={this.hear_about_source}
                        onChange={(e) => {
                            this.hear_about_source.current.setCustomValidity("")
                            this.handleHearAboutChange('hear_about_source', e)
                        }}
                        defaultValue={values.hear_about_source}
                        required>
                        <option value=""></option>
                        <option value="google">Google</option>
                        <option value="linkedin">Linkedin</option>
                        <option value="facebook">Facebook</option>
                        <option value="youtube">YouTube</option>
                        <option value="industry forum">Industry Forum</option>
                        <option value="maxbounty blog">MaxBounty Blog</option>
                        <option value="offervault">Offervault</option>
                        <option value="coference">Conference</option>
                        <option value="other">Other (specify)</option>
                    </select>
                </div>
                {this.state.showOtherTextbox &&
                    <div className="form-group mb-3 text-white text-left">
                        <label htmlFor="hear_about">Specify other source*</label>
                        <input
                            ref={this.hear_about}
                            onChange={
                                (e) => {
                                    this.hear_about.current.setCustomValidity("")
                                    this.props.handleChange('hear_about')(e);
                                }}
                            placeholder="Source"
                            defaultValue={values.hear_about}
                            className="form-control"
                            type="text" />
                    </div>}
                <div className="form-group mb-3 text-white text-left">
                    <label htmlFor="experience_level">What's your experience?*</label>
                    <select
                        ref={this.experience_level}
                        onChange={this.props.handleChange('experience_level')}
                        defaultValue={values.experience_level}
                        className="form-control"
                        required>
                        <option value=""></option>
                        <option value="0">I'm a complete beginner</option>
                        <option value="1">I have a little bit of experience, but I've never received a payment from a network</option>
                        <option value="2">I have some experience, and have received at least one payment from a network</option>
                        <option value="3">I am extremely experienced, and have received many payments from networks</option>
                    </select>
                </div>
                <div className="form-group mb-3 text-white text-left">
                    <label htmlFor="experience_description">Describe your past experience in affiliate marketing. (50-200 characters)*</label>
                    <textarea
                        ref={this.experience_description}
                        onChange={
                            (e) => {
                                this.experience_description.current.setCustomValidity("")
                                this.props.handleChange('experience_description')(e);
                            }}
                        placeholder="Please describe."
                        defaultValue={values.experience_description}
                        className="form-control"
                        maxLength="200">
                    </textarea>
                </div>
                <div className="form-group mb-3 text-white text-left">
                    <label htmlFor="promotion">How do you intend to promote campaigns? (50-200 characters)*</label>
                    <textarea
                        ref={this.promotion}
                        onChange={
                            (e) => {
                                this.promotion.current.setCustomValidity("")
                                this.props.handleChange('promotion')(e);
                            }}
                        placeholder="Please describe."
                        defaultValue={values.promotion}
                        className="form-control"
                        maxLength="200">
                    </textarea>
                </div>
                <div className="form-group mb-3 text-white text-left">
                    <label htmlFor="desired_offer">What campaign(s) are you looking to run?</label>
                    <input
                        ref={this.desired_offer}
                        onChange={
                            (e) => {
                                this.desired_offer.current.setCustomValidity("")
                                this.props.handleChange('desired_offer')(e);
                            }}
                        placeholder="Campaign name"
                        defaultValue={values.desired_offer}
                        className="form-control"
                        type="text" />
                </div>
                <div className="form-group mb-3 text-white text-left">
                    <label htmlFor="website">Your website URL (optional)</label>
                    <input
                        ref={this.website}
                        onChange={this.props.handleChange('website')}
                        placeholder="http://example.com"
                        defaultValue={values.website}
                        className="form-control"
                        type="text"
                    />
                </div>                
                <div className="d-flex flex-row-reverse">
                    <div className="pl-2 flex-1">
                        <button onClick={this.saveAndContinue} className="btn btn-lg btn-primary btn-block">Next</button>
                    </div>
                    <div className="flex-1">
                        <button onClick={this.back} className="btn btn-lg btn-secondary btn-block">Back</button>
                    </div>
                </div>
            </form>
        );
    }
}


export default FormExperience;