import React, { Component } from 'react';

class CampaignRates extends Component {

    render() {
        return (
            <div className="card my-2">
                <div className="card-header card-header-no-border">
                    Rates
                </div>
                <div className="card-body p-0 height-limited">
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Description</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">CPA Rate</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.rates.map((rate, i) => (rate.status === 'Active' &&
                                    <tr key={i}>
                                        <td>{rate.description}</td>
                                        <td>{rate.rate_type}</td>
                                        <td>{rate.rate}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default CampaignRates;