import React, { Component } from 'react';
import { Modal, Button, ModalHeader, ModalBody } from 'reactstrap';

import maxmoneyLogo from '../../assets/images/maxmoney/maxmoney-logo.png';
import maxmoneySymbol from '../../assets/images/maxmoney/maxmoney-symbol.png';

import Loading from '../../component/UI/Loading/Loading';
import MaxMoneyPromo from './MaxMoneyPromo/MaxMoneyPromo';
import MaxMoneyTransaction from './MaxMoneyTransaction/MaxMoneyTransaction';

import { clearLocalStorageAndReload, verifyToken } from '../../assets/JS/functions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './MaxMoney.css';

class MaxMoney extends Component {

    _isMounted = false;

    state = {
        dataLoaded: false,
        redeemModal: false,
        redeemLoaded: true,
        faqModal: false,
        termsModal: false,
        redeemLimit: 0,
        ongoingPromos: [],
        futurePromos: [],
        transactionHistory: []
    }

    componentDidMount() {
        this._isMounted = true;
        // Check to see if localstorage has been set
        // THIS SHOULD BE SET UPON LOGIN AT api/authenticationApp
        const mbToken = localStorage.getItem('mb-auth-token');

        /* Get the affiliate object from the DB and populate the state information */

        verifyToken().then(result => {
            if (result === true) {
                fetch(`${process.env.REACT_APP_API_URL}/maxmoney`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-app-token': mbToken
                    }
                })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result["success"] === true) {
                                if (this._isMounted) {
                                    this.setState({
                                        dataLoaded: true,
                                        redeemLimit: result.redeem_limit,
                                        ongoingPromos: result.ongoing_promos,
                                        futurePromos: result.future_promos,
                                        pastPromos: result.past_promos,
                                        transactionHistory: result.transactions
                                    });
                                }
                            } else if (result["success"] === false) {
                                if (result.code !== 401) {
                                    this.props.showSystemMessage("error", "There was an error fetching the data. Please try again.");
                                }
                            } else {
                                this.props.showSystemMessage("error", result.errors.message);
                            }
                        });
            } else {
                clearLocalStorageAndReload();
            }
        }).catch(error => {
            console.log('Error in MaxMoney: ', error);
            this.props.showSystemMessage("error", "Connection error.");
        });
    }

    toggleFaq = () => {
        this.setState({
            faqModal: !this.state.faqModal
        });
    }

    toggleTerms = () => {
        this.setState({
            termsModal: !this.state.termsModal
        });
    }

    toggleRedeem = () => {
        this.setState({
            redeemModal: !this.state.redeemModal
        });
    }

    redeem = (e) => {
        e.preventDefault();

        this.setState({
            redeemLoaded: false
        });

        let redeemAmount = Number(e.target.redeem_amount.value);
        let totalPending = 0;
        this.state.transactionHistory.map(transaction =>
            transaction.action === "Pending" && (
                totalPending = totalPending + Number(transaction.amount)
            )
        );

        //Send the data to the DB
        const mbToken = localStorage.getItem('mb-auth-token');
        const redeemBody = {
            'amount': redeemAmount
        }

        /* Get the affiliate object from the DB and populate the state information */
        fetch(`${process.env.REACT_APP_API_URL}/maxmoney/redeem`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': mbToken
            },
            body: JSON.stringify(redeemBody)
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result["success"] === true) {
                        //Update the MaxMoney amount in the application state
                        let newBalance = this.props.affiliate.maxmoney - redeemAmount;
                        if (this._isMounted) {
                            this.props.updateMaxMoney(newBalance, "success", result.message);

                            let newTransaction = {
                                entry_id: result.entry_id,
                                entry_date: Date.now(),
                                amount: redeemAmount,
                                entry_type: "Redeem",
                                notes: ""
                            }

                            //Update the transaction history
                            this.setState({
                                redeemModal: !this.state.redeemModal,
                                redeemLoaded: true,
                                ongoingPromos: this.state.ongoingPromos,
                                futurePromos: this.state.futurePromos,
                                pastPromos: this.state.pastPromos,
                                transactionHistory: [newTransaction, ...this.state.transactionHistory]
                            });
                        }

                    } else if (result["success"] === false) {
                        this.props.showSystemMessage("error", result.errors.message);
                        if (this._isMounted) {
                            this.setState({
                                redeemLoaded: true,
                            });
                        }
                    } else {
                        this.props.showSystemMessage("error", result.errors.message);
                        if (this._isMounted) {
                            this.setState({
                                redeemLoaded: true,
                            });
                        }
                    }
                }).catch(error => {
                    this.props.showSystemMessage("error", "Connection error.");
                    this.setState({
                        redeemLoaded: true,
                    });
                });
    }

    cancelTransaction = (transactionId) => {
        let transactions = this.state.transactionHistory;

        const mbToken = localStorage.getItem('mb-auth-token');
        const cancelBody = {
            'entry_id': transactionId
        }

        /* Get the affiliate object from the DB and populate the state information */
        fetch(`${process.env.REACT_APP_API_URL}/maxmoney/cancel`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': mbToken
            },
            body: JSON.stringify(cancelBody)
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result["success"] === true) {
                        //Update the MaxMoney amount in the application state
                        let newBalance = this.props.affiliate.maxmoney + Number(transactions.find(transaction => transaction.entry_id === transactionId).amount);
                        if (this._isMounted) {
                            this.props.updateMaxMoney(newBalance, "success", result.message);

                            //Update the transaction history log
                            const newTransactions = transactions.filter(transaction => transaction.entry_id !== transactionId);
                            this.setState({
                                redeemModal: this.state.redeemModal,
                                ongoingPromos: this.state.ongoingPromos,
                                futurePromos: this.state.futurePromos,
                                pastPromos: this.state.pastPromos,
                                transactionHistory: newTransactions
                            });
                        }
                    } else if (result["success"] === false) {
                        this.props.showSystemMessage("error", result.errors.message);
                    } else {
                        this.props.showSystemMessage("error", result.errors.message);
                    }
                }).catch(error => {
                    console.log('Error in MaxMoney cancel: ', error);
                    this.props.showSystemMessage("error", "Connection error.");
                });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <React.Fragment>
                {this.state.dataLoaded ?
                    <div className="row">

                        <div className="col-lg-3 split-left">

                            <div className="card my-2 text-white bg-dark">
                                <div className="card-header">
                                    <div className="flex-1">Your MaxMoney Balance</div>
                                </div>
                                <div className="card-body text-center">
                                    <div style={{ fontSize: '3em', borderBottom: '1px solid RGBA(255,255,255,0.1)' }}>
                                        <img src={maxmoneySymbol} style={{ maxWidth: '50px', marginTop: '-5px' }} alt="MaxMoney Symbol" />
                                        <span className="maxmoney-gold ml-2">{parseFloat(this.props.affiliate.maxmoney).toFixed(2)}</span>
                                    </div>
                                    <div className="mt-3">
                                        <button className="btn btn-info btn-sm" onClick={this.toggleRedeem}>Redeem for cash</button>
                                        <Modal isOpen={this.state.redeemModal} toggle={this.toggleRedeem} size='md'>
                                            <ModalHeader toggle={this.toggleRedeem}>Redeem MaxMoney Points</ModalHeader>
                                            <ModalBody>
                                                <p>Redemption limit: {this.state.redeemLimit} points per week.</p>
                                                <form onSubmit={this.redeem}>
                                                    <div className="form-group">
                                                        <input name="redeem_amount" type="text" className="form-control" placeholder="Amount to redeem" />
                                                    </div>
                                                    <div className="text-center">
                                                        <Button color="secondary" onClick={this.toggleRedeem}>Close</Button>
                                                        {this.state.redeemLoaded ?
                                                            <button type="submit" className="ml-2 btn btn-primary">Submit</button>
                                                            :
                                                            <button type="submit" className="ml-2 btn btn-primary" disabled><FontAwesomeIcon icon="spinner" spin /></button>
                                                        }
                                                    </div>
                                                </form>
                                            </ModalBody>
                                        </Modal>
                                    </div>
                                </div>
                            </div>

                            <div className="card my-2 text-white bg-dark">
                                <div className="card-header">
                                    <div className="flex-1">Transaction History</div>
                                </div>
                                <div className="card-body text-center">
                                    <table style={{ width: '100%' }}>
                                        <tbody>
                                            <tr className="tr_header" style={{ borderBottom: '1px solid RGBA(255,255,255,0.05)' }}>
                                                <th className="text-left">Date</th>
                                                <th className="text-left">Action</th>
                                                <th className="text-right">Amount</th>
                                                <th>&nbsp;</th>
                                            </tr>
                                            {
                                                this.state.transactionHistory.length === 0 && (
                                                    <tr>
                                                        <td colSpan="3" className="text-left font-italic"> You have no transactions.</td>
                                                    </tr>
                                                )
                                            }
                                            {
                                                this.state.transactionHistory.length > 0 && (
                                                    //Create a shallow copy of the array, and print it in reverse order.
                                                    this.state.transactionHistory.map(transaction => (
                                                        <MaxMoneyTransaction key={transaction.entry_id} data={transaction} handleCancel={this.cancelTransaction} />
                                                    ))
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>

                        <div className="col-lg-9 split-right">

                            <div className="card my-2 text-white bg-dark">
                                <div className="card-header">
                                    <div className="text-center">
                                        <img src={maxmoneyLogo} style={{ maxWidth: '250px', width: '100%' }} alt="MaxMoney Logo" />
                                    </div>
                                </div>
                                <div className="card-body text-center">
                                    <div className="text-center">
                                        <p>Get rewarded, not just with praise but with more profit in your pocket.<br></br>Earn MaxMoney during events that occur throughout each month and redeem them for cash at your convenience.<br></br>That's MaxMoney - increasing the return on your hard work, only at MaxBounty.</p>
                                        {/* <a href="#" className="dark" onClick={this.toggleFaq}>FAQ</a> - <a href="#" className="dark" onClick={this.toggleTerms}>Terms</a> */}
                                        <button type="button" className="dark btn-link btn btn-sm p-0 m-0" style={{ cursor: "pointer" }} onClick={this.toggleFaq}>FAQ</button> - <button type="button" className="dark btn-link btn btn-sm p-0 m-0" style={{ cursor: "pointer" }} onClick={this.toggleTerms}>Terms</button>
                                        <Modal isOpen={this.state.faqModal} toggle={this.toggleFaq} size='lg'>
                                            <ModalHeader toggle={this.toggleFaq}>Frequently Asked Questions</ModalHeader>
                                            <ModalBody>
                                                <div>
                                                    <p className="font-weight-bold mb-1">What is MaxMoney?</p>
                                                    <p>MaxMoney is a rewards program, found only at MaxBounty. By participating in promotions that have you advertise and earn on specific campaigns during set time periods, you will earn points called MaxMoney.</p>
                                                </div>
                                                <div>
                                                    <p className="font-weight-bold mb-1">How much is MaxMoney worth?</p>
                                                    <p>One MaxMoney point is equal to one US Dollar.</p>
                                                </div>
                                                <div>
                                                    <p className="font-weight-bold mb-1">How are MaxMoney points earned?</p>
                                                    <p>Each MaxMoney promotion will reward a specific bonus percentage based on your total earnings for the given campaign. For example, "Earn a 10% MaxMoney Bonus, for running Campaign X, between Jan 1st and Jan 7th" If you earned $1000 dollars in affiliate earnings, on campaign X during the set period, you would earn 100 MaxMoney points (the equivalent of 100 USD). </p>
                                                </div>
                                                <div>
                                                    <p className="font-weight-bold mb-1">How can I redeem MaxMoney points?</p>
                                                    <p>Your MaxMoney dashboard will feature a "Redeem for cash" button, which you can use whenever you want. These points will be formally added as cash, to your account balance every Sunday, and paid out on the next payment period you would normally qualify for.</p>
                                                </div>
                                                <div>
                                                    <p className="font-weight-bold mb-1">How can I take part?</p>
                                                    <p>All MaxBounty affiliates are eligible to participate but must opt in to the program as well as each individual promotion, through the MaxMoney dashboard page by simply clicking one button.</p>
                                                </div>
                                                <div>
                                                    <p className="font-weight-bold mb-1">How can I check my results/progress?</p>
                                                    <p>Each affiliate will have a section in the MaxMoney dashboard page which will display their results of past promotions, and results of current promotions.</p>
                                                </div>
                                                <div>
                                                    <p className="font-weight-bold mb-1">Where can I see my MaxMoney total balance?</p>
                                                    <p>Your balance can be found on the MaxMoney dashboard page and also in the top right-hand corner of every screen in the dashboard, next to the gold MaxMoney logo.</p>
                                                </div>
                                                <div>
                                                    <p className="font-weight-bold mb-1">When do MaxMoney promotions take place?</p>
                                                    <p>A new MaxMoney promotion will begin every two weeks and begin as another one ends. In addition to these two week promotions, there will also be periodic flash promotions which will be announced and run for a few days.</p>
                                                </div>
                                                <div>
                                                    <p className="font-weight-bold mb-1">What happens to leads I generate that qualifies for multiple promotions?</p>
                                                    <p>If your leads qualify for multiple promotions, you will be rewarded on the promotion that targets the specific offer..</p>
                                                </div>
                                            </ModalBody>
                                        </Modal>
                                        <Modal isOpen={this.state.termsModal} toggle={this.toggleTerms} size='lg'>
                                            <ModalHeader toggle={this.toggleTerms}>Terms and conditions</ModalHeader>
                                            <ModalBody>
                                                <div>
                                                    <p>If you are a MaxBounty affiliate and have registered for any individual MaxMoney promotion, you agree to the following terms and conditions which form the MaxBounty MaxMoney Terms and Conditions Affiliate Agreement between you and us. In these terms and conditions, "you" means the Affiliate.</p>
                                                </div>
                                                <div>
                                                    <p className="font-weight-bold mb-1">When MaxMoney Points Are Issued</p>
                                                    <p>Earnings on promotion campaigns, less any reversals shown on your account statements, qualify for MaxMoney points if:  your account is in good standing, and these are earnings for a campaign which you have registered for the specific MaxMoney promotion.</p>
                                                </div>
                                                <div>
                                                    <p className="font-weight-bold mb-1">When MaxMoney Points Are Not Issued</p>
                                                    <p>Earnings on campaigns where you have not registered for the corresponding MaxMoney promotion do not qualify for MaxMoney Points. We may establish other qualifying and nonqualifying actions from time to time.</p>
                                                </div>
                                                <div>
                                                    <p className="font-weight-bold mb-1">How MaxMoney Points Are Issued</p>
                                                    <p>Where earnings qualify for MaxMoney Points, MaxMoney Points will be issued live and will be added to your MaxMoney balance statement. If your account statement shows more reversals than earnings, MaxMoney Points will be deducted from accumulated MaxMoney Points or from future MaxMoney Points you receive would be due to receive.</p>
                                                </div>
                                                <div>
                                                    <p className="font-weight-bold mb-1">Redeeming MaxMoney Points</p>
                                                    <p>You may redeem MaxMoney Points at any time. Points redeemed before Monday at 11:59pm qualify for payment the following Wednesday. Points redeemed after Monday at 11:59pm will not qualify to be paid out until the following pay period. We may cancel or reverse any MaxMoney Points not issued correctly. We may refuse to issue MaxMoney Points or may withdraw MaxMoney Points already issued if your Account is not in good standing.</p>
                                                </div>
                                                <div>
                                                    <p className="font-weight-bold mb-1">Accumulating, Redeeming and Crediting Your Account with MaxMoney Points</p>
                                                    <p>You will accumulate MaxMoney Points over the course of a calendar year beginning with the earnings you had from the first MaxMoney Promotion you registered for. You may accumulate up to 10,000 MaxMoney Points at which time you are forced to redeem some of the balance in order to qualify to register for further MaxMoney Promotions.</p>
                                                </div>
                                                <div>
                                                    <p className="font-weight-bold mb-1">When We May Not Credit Your Account</p>
                                                    <p>We may not credit your account if your account has been closed or if it is not in good standing. As well, if you have a negative MaxMoney Points balance (because you have had more reversals than new earnings), MaxMoney Points will be deducted from accumulated MaxMoney Points or from MaxMoney Points you receive later.</p>
                                                </div>
                                                <div>
                                                    <p className="font-weight-bold mb-1">Transferability</p>
                                                    <p>MaxMoney Points are not transferable to any other account.</p>
                                                </div>
                                                <div>
                                                    <p className="font-weight-bold mb-1">Amendment and Cancellation</p>
                                                    <p>We have the right at any time to amend these terms or cancel the MaxMoney program. If we cancel the MaxMoney program, we will credit your account for the MaxMoney Points you have previously earned, unless your account is not in good standing at that time.</p>
                                                </div>
                                            </ModalBody>
                                        </Modal>
                                    </div>

                                    {
                                        this.state.ongoingPromos.length === 0 && this.state.futurePromos.length === 0 && (
                                            <React.Fragment>
                                                <hr style={{ margin: '0px auto' }} className="short thick red mt-3"></hr>
                                                <div className="alert alert-info mt-3">There are currently no events planned.</div>
                                            </React.Fragment>
                                        )
                                    }

                                    {
                                        this.state.ongoingPromos.length > 0 && (
                                            <React.Fragment>
                                                <hr className="light"></hr>
                                                <h4 className="text-white text-left">Ongoing Promos</h4>

                                                {
                                                    this.state.ongoingPromos.map(promo => (
                                                        <MaxMoneyPromo data={promo} key={promo.promo_id} showSystemMessage={this.props.showSystemMessage} type='ongoing' />
                                                    )
                                                    )
                                                }
                                            </React.Fragment>
                                        )
                                    }

                                    {
                                        this.state.futurePromos.length > 0 && (
                                            <React.Fragment>
                                                <hr className="light"></hr>
                                                <h4 className="text-white text-left">Future Promos</h4>

                                                {
                                                    this.state.futurePromos.map(promo => (
                                                        <MaxMoneyPromo data={promo} key={promo.promo_id} showSystemMessage={this.props.showSystemMessage} type='future' />
                                                    ))
                                                }
                                            </React.Fragment>
                                        )
                                    }

                                    {
                                        this.state.pastPromos.length > 0 && (
                                            <React.Fragment>
                                                <hr className="light"></hr>
                                                <h4 className="text-white text-left">Past Promos</h4>

                                                {
                                                    this.state.pastPromos.map(promo => (
                                                        <MaxMoneyPromo data={promo} key={promo.promo_id} showSystemMessage={this.props.showSystemMessage} type='past' />
                                                    ))
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                </div>
                            </div>

                        </div>

                    </div>
                    :
                    // Data is not loaded yet
                    <div style={{ marginTop: '4rem' }}>
                        <Loading />
                    </div>

                }
            </React.Fragment>
        );
    }
}

export default MaxMoney;