import React from 'react';
import './Footer.css';

const footer = () => (
    <div className="card Footer">
        <div className="text-muted font-weight-bold" style={{ padding: "10px", alignItems: "center" }}>
            MaxBounty Inc. [{window.globalConfig.wsid || 0}]
        </div>
    </div>
);


export default footer;