import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './CampaignCardLoading.css';

const singleCampaign = (props) => (
    <div className="Campaigncard mb-card">
        <div className="mb-card-img-top-loading d-flex align-items-center justify-content-center">
            <FontAwesomeIcon size="3x" icon="spinner" spin />
        </div>
        <div className="card-body d-flex flex-column justify-content-between text-left">
            <p className="font-weight-bold" style={{minHeight:"50px"}}></p>
        </div>
    </div>
);

export default singleCampaign;