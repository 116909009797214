import React from 'react';

import AccountDropdown from '../DropdownMenus/AccountDropdown/AccountDropdown';
import NewsDropdown from '../DropdownMenus/NewsDropdown/NewsDropdown';
import NavigationDropup from '../DropdownMenus/NavigationDropup/NavigationDropup';

import './BottomToolbar.css';

const bottomToolbar = () => (
    <nav className="bottom-nav navbar fixed-bottom">
        {/* className="navbar-dark navbar bottom-nav fixed-bottom" */}
        <div className="text-white">
            {/* <a href="#">
                <User size={16} color="white"/>  */}
            {/* <SystemItems /> */}
            <span className="mobile-only"><AccountDropdown /></span>

            {/* </a> */}
        </div>

        {/* Add MenuItems */}
        <div className="text-white">
            <span className="mobile-only"><NavigationDropup /></span>
        </div>
        {/* Add SquareItems */}
        <div className="text-white">
            {/* <a href="#" className="dropdown-toggle">
                <Square size={16} color="white" />
            </a> */}
            <span className="mobile-only"><NewsDropdown /></span>
        </div>

    </nav>
);

export default bottomToolbar;