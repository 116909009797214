import React, { Component } from 'react';

import { CSSTransition } from 'react-transition-group';
import _ from 'lodash';

import SingleAlert from './SingleAlert/SingleAlert';
import './AlertPop.css';
import { clearLocalStorageAndReload } from '../../../assets/JS/functions';

class AlertPop extends Component {

    /*
        1. On first load, componentDidMount() extecutes and fetches the notification via fetchNotifications().
        2. It also sets an interval to clear existing notifications and fetch new ones at every x times by executing clearAndFetch().
        3. setPopLoaded() adds a boolean property to alerts to help with animation and show/hide
        4. clearAllNotifications() loops through the existing notifications and sets the status to 'V'(viewed) to db so they don't show up again. This function also clears the alerts from state and hides Clear All button
        5. singlePopCloseHandler() executes when X is clicked on individual notifications
    */

    constructor(props) {
        super(props);
        this.getNotificationsTimer = 0;
        this.state = {
            alerts: [],
            showClearAll: false
        }
    }

    componentDidMount() {

        this.fetchNotifications();
        this.popInterval = setTimeout(() => {   // alert pop comes up 3 seconds after page is done rendering
            this.setPopLoaded();
        }, 3000);

        this.getNotificationsTimer = setInterval(this.clearAndFetch, process.env.REACT_APP_NOTIFICATIONS_TIMER);    // 2
    }

    componentWillUnmount() {
        clearInterval(this.getNotificationsTimer);
    }

    fetchNotifications = async () => {  // 1

        const authtoken = localStorage.getItem('mb-auth-token');

        try {
            const promise = await fetch(`${process.env.REACT_APP_API_URL}/affiliate/notifications`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-app-token': authtoken
                }
            });
            
            if (promise.status === 401) {
                clearLocalStorageAndReload();
            }

            const result = await promise.json();

            if (result["success"] === true && result.notifications.length > 0) {
                this.setState({ alerts: result.notifications, showClearAll: true }, () => this.setPopLoaded())
            } else if (result["success"] === false) {
                this.setState({ showClearAll: false });
            }
        } catch (error) {
            console.log('Error while fetching notifications: ', error);
        }
    }

    // add a boolean property called 'popLoaded' to each alert to use it in CSSTransition
    setPopLoaded = () => {  // 3
        const newAlert = this.state.alerts.map(alert => {
            return { ...alert, popLoaded: true };
        });
        this.setState({ alerts: newAlert });
    }

    clearAndFetch = () => {
        this.clearAllNotifications();
        setTimeout(this.fetchNotifications, 5000);
    }

    // Closing an individual notification through 'X'
    popCloseHandler = (key) => {    // 5
        const authtoken = localStorage.getItem('mb-auth-token');

        fetch(`${process.env.REACT_APP_API_URL}/affiliate/notifications`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authtoken
            },
            body: JSON.stringify({ news_id: key })
        })
            .then(res => res.json())
            .then(result => {
                if (result["success"] === true) {

                    let index = _.findIndex(this.state.alerts, { news_id: key });
                    const newAlerts = this.state.alerts.slice();

                    if (index !== -1) {
                        newAlerts[index].popLoaded = false;
                        this.setState({ alerts: newAlerts });
                    }

                    if (_.findIndex(this.state.alerts, { popLoaded: true }) === -1) {
                        this.setState({ showClearAll: false });
                    }

                } else if (result["success"] === false) {
                    return this.setState({ showClearAll: false });
                }
            })
            .catch(error => error);
    }

    clearAllNotifications = async () => { // 4

        const authtoken = localStorage.getItem('mb-auth-token');

        return Promise.all(this.state.alerts.map(async alert => {
            let promise, response;

            try {
                promise = await fetch(`${process.env.REACT_APP_API_URL}/affiliate/notifications`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-app-token': authtoken
                    },
                    body: JSON.stringify({ news_id: alert.news_id })
                });
                response = await promise.json();
            } catch (error) {
                console.log('Error while clearing notifications', error);
            }
            return response;
        }))
            .then(response => this.setState({ alerts: [], showClearAll: false }))
            .catch(error => error);
    }

    render() {

        let items = this.state.alerts.map((alert) => {
            return <CSSTransition
                in={alert.popLoaded}
                timeout={1000}
                classNames="alertPopup"
                appear
                unmountOnExit
                key={alert.news_id}>

                <SingleAlert alert={alert} popClosed={this.popCloseHandler} />

            </CSSTransition>
        });

        return (
            <div className="alert-container">
                <CSSTransition
                    in={this.state.showClearAll}
                    timeout={1000}
                    classNames="alertPopup"
                    appear
                    unmountOnExit>
                    <div className="justify-content-end d-flex pb-1 position-relative" style={{ fontSize: "10px" }}>
                        <button type="button" className="btn btn-sm btn-dark" onClick={this.clearAllNotifications}>Clear All</button>
                    </div>
                </CSSTransition>
                {items}
            </div >
        );
    }
}

export default AlertPop;