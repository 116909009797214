import React, { Component } from 'react';
import SliderCard from './SliderCard/SliderCard';
import { CSSTransition } from 'react-transition-group';

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import './Sliders.css';

class Sliders extends Component {

    _isMounted = false;

    state = {
        dataLoaded: false,
        sliders: []
    }

    componentDidMount() {

        this._isMounted = true;
        const mbToken = localStorage.getItem('mb-auth-token');

        /* Get the affiliate object from the DB and populate the state information */
        fetch(`${process.env.REACT_APP_API_URL}/campaignSlider`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': mbToken
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result["success"] === true) {
                        if (this._isMounted) {
                            this.setState({
                                dataLoaded: true,
                                sliders: result.sliders
                            });
                        }
                    } else if (result["success"] === false) {
                        console.log(result["message"]);
                    } else {
                        console.log(result.errors.message);
                    }
                },
                (error) => {
                    console.log(error);
                }
            )
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getPinnedSlider = () => {
        let pinned_slider = this.state.sliders.find(slider => slider.pinned === 'Y');
        if (pinned_slider) {
            return <SliderCard
                key={pinned_slider.campaign_id}
                campaign_id={pinned_slider.campaign_id}
                imgURL={pinned_slider.image}
                altImg={pinned_slider.thumbnail}
                message={pinned_slider.description}
                cpa={pinned_slider.rate}
                type={pinned_slider.rate_type}
                buttonText={pinned_slider.button}
                url={pinned_slider.url}
                pinned
            />
        }
    }

    getSliders = () => {
        let sliders = this.state.sliders.filter(slider => slider.pinned !== 'Y');

        return sliders.map(slider =>
            <SliderCard
                key={slider.campaign_id}
                campaign_id={slider.campaign_id}
                imgURL={slider.image}
                altImg={slider.thumbnail}
                message={slider.description}
                cpa={slider.rate}
                type={slider.rate_type}
                buttonText={slider.button}
                url={slider.url}
            />
        )
    }

    render() {
        const settings = {
            autoplay: true,
            autoplaySpeed: 6000,
            dots: false,
            infinite: true,
            speed: 300,
            arrows: false,
            variableWidth: true
        }
        return (

            <CSSTransition
                in={this.state.dataLoaded}
                appear={true}
                classNames="fadeIn"
                timeout={500}>
                <div style={{ display: "flex" }} className="mt-2 mb-slider">
                    {this.getPinnedSlider()}
                    <div style={{ overflow: "hidden" }}>
                        <Slider {...settings}>

                            {this.getSliders()}

                        </Slider>
                    </div>
                </div>
            </CSSTransition>
        );
    }
}

export default Sliders;