import React from 'react';
import { Link } from 'react-router-dom';

const singleCampaign = (props) => (
    <div className={props.classes}>
        <Link
            to={`/campaign/${props.campaignId}`}
            className="mb-card-img-top"
            style={{ backgroundImage: `URL(${ props.thumbnail })` }}>
        </Link>
        <div className="card-body d-flex flex-column justify-content-between text-left">
            <p className="font-weight-bold">{ props.name }</p>
            <div className="mb-campaign-price text-center">
                {props.rate}
            </div>
        </div>
    </div>
);

export default singleCampaign;