import React, { Component } from 'react';
import { withRouter } from 'react-router';

import AutoCompleteList from './AutoCompleteList/AutoCompleteList';

import { debounce } from '../../../../assets/JS/functions';
import './SearchCampaigns.css';

class SearchCampaigns extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showAutoCompleteList: false,    // show/hide autocompletelist
            quickSearchTerm: '',            // save search term to make API calls
            matchingCampaigns: [],          // save search results to show in autocompletelist
            dataLoaded: false,               // delay loading while search results are still being fetched
            selectedOfferId: null,
            isOfferClicked: false
        };
        this.getQuickSearchData = debounce(this.getQuickSearchData, 500);
        this.formSubmitted = false;
    }

    // Called as user types in
    quickSearchChangeHandler = (event) => {
        // event.preventDefault();
        this.formSubmitted = false;
        let inputValue = (event.target.value);

        if (inputValue.trim()) {
            this.setState({ quickSearchTerm: inputValue }, () => {
                this.getQuickSearchData(this.state.quickSearchTerm.trim());
            });

        } else {
            this.setState({ showAutoCompleteList: false, quickSearchTerm: '', dataLoaded: false });
        }
    }

    // formSubmitHandler method is called when submit the quich form in toolbar
    /*
       There could be two cases when the form is submitted: 
            case 1. With just search text (might not exactly match to the campaign name or ID)
            case 2. Submit the form after selecting a particular campaign from the autocomplete list (form gets populated with the campaign name after the click)
    */
    formSubmitHandler = (event) => {
        this.formSubmitted = true;
        event.preventDefault();
        this.setState({ showAutoCompleteList: false });

        let form = document.getElementById('quickSearchForm');
        form.reset();

        if (this.state.isOfferClicked) {    // case 2
            this.props.history.push({
                pathname: `/campaign/${this.state.selectedOfferId}`
            });
        } else {    // case 1
            this.props.history.push({
                pathname: `/search`,
                state: { quickSearchQuery: this.state.quickSearchTerm }
            });
        }
        this.setState({ quickSearchTerm: '', dataLoaded: false, isOfferClicked: false });
    }

    // Get quick search data to show matching autocomplete list as user types in 
    getQuickSearchData = (quickSearchQuery) => {

        const authtoken = localStorage.getItem('mb-auth-token');

        fetch(`${process.env.REACT_APP_API_URL}/search?q=${quickSearchQuery}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authtoken
            }
        })
            .then(res => res.json())
            .then(result => {
                if (result["success"] === true) {
                    if (this.formSubmitted) {   // Do not show autocomplete list if search form is submitted.
                        this.setState({
                            dataLoaded: true,
                            matchingCampaigns: result["matchingCampaigns"]
                        });
                    } else {
                        this.setState({
                            dataLoaded: true,
                            showAutoCompleteList: true,
                            matchingCampaigns: result["matchingCampaigns"]
                        });
                    }
                } else if (result["success"] === false) {
                    this.setState({ dataLoaded: true, showAutoCompleteList: false });
                } else {
                    this.props.showSystemMessage("error", "There was an error while fetching quick search results.");
                    this.setState({
                        dataLoaded: true,
                        showAutoCompleteList: false
                    });
                }
            });
    }

    // Dismiss list when clicked outside
    toggle = () => {
        this.setState({ showAutoCompleteList: false });
    }

    // Called when a specific offer from autocomplete list is called
    listClickHandler = (event) => {
        this.setState({ isOfferClicked: true, selectedOfferId: event.target.getAttribute('offerid'), quickSearchTerm: event.target.getAttribute('name') });
    }

    render() {

        let autocompleteClasses = "noshow";

        if (this.state.showAutoCompleteList) {
            autocompleteClasses = "ui-autocomplete";
        }

        return (
            <form className="SearchCampaigns" method="get" onSubmit={this.formSubmitHandler} id="quickSearchForm">
                <input className="form-control form-control-dark" name="q" aria-label="Search"
                    placeholder="Search campaigns by name or #ID" type="text" style={{ height: "51px", fontSize: "14px" }}
                    onChange={this.quickSearchChangeHandler}
                    autoComplete="off" onBlur={this.toggle}
                    value={this.state.quickSearchTerm}
                />

                <ul className={autocompleteClasses} id="ui-id-1" tabIndex="0" >
                    {(this.state.showAutoCompleteList && this.state.dataLoaded) &&

                        this.state.matchingCampaigns.map(match =>
                            <AutoCompleteList
                                key={match.campaign_id}
                                offerId={match.campaign_id}
                                name={match.name}
                                listClicked={this.listClickHandler}
                            />
                        )}
                </ul>
            </form>
        );
    }
}

export default withRouter(SearchCampaigns);
