
export const yesNo = (value) => {
    if (value === 'Y') {
        return "Yes"
    } else {
        return "No"
    }
}

export function debounce(func, wait, immediate) {
    let timeout;

    return function () {
        let context = this, args = arguments;

        let later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };

        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);

        if (callNow) func.apply(context, args);
    }
}

export function clearLocalStorageAndReload() {
    localStorage.removeItem('mb-aid');
    localStorage.removeItem('mb-auth-token');
    localStorage.removeItem('mb-t');
    window.location.reload();
}

export function verifyToken() {
    const authtoken = localStorage.getItem('mb-auth-token');

    return fetch(`${process.env.REACT_APP_API_URL}/application/verifyToken`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'x-app-token': authtoken
        }
    })
        .then(res => res.json())
        .then(result => {
            if (result["success"] === false) {
                if (result.code === 401) {
                    return false;
                } else {
                    return false;
                }
            }
            return true;
        });
}

export async function generateVerificationCode(via, source) {

    // via -> to send code via email or text or both
    // source -> Need the extra param to distinguish between request coming from Settings or Login

    const authToken = localStorage.getItem('mb-auth-token');

    let url = `${process.env.REACT_APP_API_URL}/affiliate/generatecode`;

    if (source === 'login') {
        url = `${url}?l=true`;
    }

    const res = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'x-app-token': authToken
        },
        body: JSON.stringify({
            via: via,
            source: source
        })
    });

    const result = await res.json();
    if (result["success"] === false) {
        return {
            "success": false
        };
    }
    else {
        return {
            "success": true,
            "t": result.t
        };
    }
}

export async function verifyOTPToken(code) {

    const authToken = localStorage.getItem('mb-auth-token');

    const res = await fetch(`${process.env.REACT_APP_API_URL}/affiliate/verifycode`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'x-app-token': authToken
        },
        body: JSON.stringify({
            code: code
        })
    });

    const result = await res.json();

    return result;
}

export async function getCsrfToken() {
    return await fetch(`${process.env.REACT_APP_API_URL}/application/appToken`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(res => res.json())
        .then(result => {
            if (result["success"] === true) {
                return { success: true, token: result.token };
            } else {
                console.log('Request failed');
                return { success: false }
            }
        })
        .catch(error => {
            console.log(error)
            return {success: false}
        });
}
