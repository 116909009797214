import React from 'react';

import './RadioButton.css';

const radioButton = (props) => {


    // have to detrmine here if the button request is coming from traffic or creative section to set the name, id etc

    let trafficId = props.type + "-" + props.id;
    let name = '';

    if (props.type === 'creative') {
        name = 'creativeType';
    } else {
        name = 'trafficType';
    }

    return (
        <div className="mb-radio" data-tip={props.tooltip}>
            <div className="custom-control custom-radio">
                <input type="radio" id={trafficId} name={name} value={props.value} className="custom-control-input"  onClick={props.changed} />
                <label className="custom-control-label pr-2" htmlFor={trafficId}>
                    {props.buttonName}
                </label>
            </div>
        </div>
    );
}

export default radioButton;


// Two options:
// 1. Either get the allowed traffic types and pass their properties as props from 'SingleCampaign' component by looping through OR
// 2. Send the whole array or list of allowed traffic to this page and have a loop here to parse and create radio buttons 