import React, { Component } from 'react';

class CampaignCreatives extends Component {

    getCreativeList = () => {
        const uniqueCreativeTypes = [...new Set(this.props.creatives.map(creative => creative.type))];
        let rows = [];
        uniqueCreativeTypes.forEach(creativeType => {
            if (creativeType === 'banner') {
                rows.push(  <tr key="banners">
                                <td>Banners</td>
                                <td align="right"><button className="btn btn-sm btn-link p-0 m-0" onClick={this.props.showBannerCreatives}>View</button></td>
                            </tr> )
            } else if (creativeType === 'text') {
                rows.push(  <tr key="text">
                                <td>Text Links</td>
                                <td align="right"><button className="btn btn-sm btn-link p-0 m-0" onClick={this.props.showTextCreatives} >View</button></td>
                            </tr>)
            } else if (creativeType === 'textEmail') {
                rows.push(  <tr key="textEmail">
                                <td>Solo E-mail (text)</td>
                                <td align="right"><button className="btn btn-sm btn-link p-0 m-0" onClick={() => this.props.showEmailCreatives('E')} >View</button></td>
                            </tr>)
            } else if (creativeType === 'htmlEmail') {
                rows.push(  <tr key="htmlEmail">
                                <td>Solo E-mail (html)</td>
                                <td align="right"><button className="btn btn-sm btn-link p-0 m-0" onClick={() => this.props.showEmailCreatives('H')} >View</button></td>
                            </tr>)
            }
        });
        return rows;
    }

    render() {
        return (
            <div className="card my-2">
                <div className="card-header card-header-no-border">
                    Creatives
                </div>
                <div className="card-body p-0 height-limited">
                    <div className="table-responsive">
                        <table className="table">
                            <tbody>
                                {this.getCreativeList()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default CampaignCreatives;