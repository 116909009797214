import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import maxbountyLogoSquare from '../../../assets/images/MaxBounty_Square.png';
import { generateVerificationCode } from '../../../assets/JS/functions';


class LoginOTP extends Component {
    state = {
        code: 0,
        isCodeSent: false,
        selectedOtpOption: 'byEmail'
    }

    handleInput = (e) => {
        e.preventDefault();
        this.setState({ code: e.target.value.trim() });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.verifyCode(this.state.code);
    }

    handleOptionChange = (e) => {
        this.setState({ selectedOtpOption: e.target.getAttribute('id') });
    }

    sendCode = async (e) => {
        e.preventDefault();

        await generateVerificationCode(this.state.selectedOtpOption, 'login').then(result => {
            if (result.success === true) {
                this.setState({ isCodeSent: true });
            }
        });
    }

    render() {
        let isMobileAdded = this.props.isMobileAdded;
        let updateMobileText = '';

        if (!isMobileAdded) {
            updateMobileText = <span className="text-danger pl-2" style={{ fontSize: '15px' }}>(Add your mobile number after logging in to receive code via SMS)</span>
        }

        return (
            <div>
                <div className="panel">
                    <img className="mb-4 rounded" src={maxbountyLogoSquare} alt="" width="100" />
                </div>
                <div className="card text-left">
                    <div className="card-header font-weight-bold">
                        Please verify your account
                </div>
                    {this.state.isCodeSent ?
                        <div className="card-body">
                            <div className="mb-4">
                                We have sent you a code.
                            </div>

                            <form className="my-2" method="post" onSubmit={this.handleSubmit}
                                autoComplete="off"
                            >
                                <input type="hidden" name="secret" value="" />
                                <div className="form-group">
                                    <input type="text" name="token" className="form-control"
                                        onChange={this.handleInput}
                                        autoFocus
                                    />
                                </div>

                                <div style={{ color: 'red' }} className="pb-3">{this.props.errorMessage}</div>

                                <div className="form-group mt-4">
                                    <input className="btn btn-primary" type="submit" value="Submit" />
                                    <button className="btn btn-link" onClick={this.props.modalClosed}>Cancel</button>
                                </div>
                            </form>
                        </div>
                        :

                        <div className="card-body">
                            <div className="mb-4">
                                We'll send you a verification code now. This added layer of security helps to keep your account secure.
                    </div>
                            <div className="mb-4">
                                How would you like to receive your code?
                    </div>

                            <form className="my-2" method="post" onSubmit={this.sendCode}
                                autoComplete="off"
                            >
                                {/* <input type="hidden" name="secret" value="" /> */}
                                <div style={{ fontSize: "15px" }}>
                                    <div className="form-check pb-2">
                                        <input className="form-check-input" type="radio"
                                            name="otpOptions"
                                            id="byEmail"
                                            onChange={this.handleOptionChange}
                                            checked={this.state.selectedOtpOption === 'byEmail'}
                                        />
                                        <label className="form-check-label" htmlFor="byEmail">
                                            Send by email
                            </label>
                                    </div>
                                    <div className="form-check pb-2">
                                        <input className="form-check-input" type="radio"
                                            name="otpOptions"
                                            id="bySMS"
                                            onChange={this.handleOptionChange}
                                            checked={this.state.selectedOtpOption === 'bySMS'}
                                            disabled={!isMobileAdded}
                                        />
                                        <label className="form-check-label" htmlFor="bySMS">
                                            Send by SMS {updateMobileText}
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio"
                                            name="otpOptions"
                                            id="byBoth"
                                            onChange={this.handleOptionChange}
                                            checked={this.state.selectedOtpOption === 'byBoth'}
                                            disabled={!isMobileAdded}
                                        />
                                        <label className="form-check-label" htmlFor="byBoth">
                                            Send on both
                            </label>
                                    </div>

                                    <div className="form-group mt-4">
                                        <input className="btn btn-primary" type="submit" value="Send Code" />
                                        <button className="btn btn-link" onClick={this.props.modalClosed}>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default withRouter(LoginOTP);